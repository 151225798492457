%button {
  @include font-size(14);

  background-color: $color-primary;
  border: 0;
  border-radius: 4px;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  margin-bottom: $gutter;
  padding: 5px 10px;
  text-decoration: none;
  text-transform: none;
  transition: all .2s ease;
  white-space: nowrap;

  &:hover {
    background: $color-hover;
    color: $color-white;
  }

  .fa {
    font-size: 95%;
    margin-right: 10px;
  }
}

.title__link {
  border-bottom: 1px solid $color-primary;
  color: $color-primary !important; /* stylelint-disable-line declaration-no-important */
  font-weight: 700;

  &:hover {
    border-bottom-color: $color-hover;
    color: $color-hover !important; /* stylelint-disable-line declaration-no-important */
  }
}

.link,
.linkmodel5 {
  color: $color-primary;
  transition: all 200ms ease;

  &:hover {
    color: $color-hover;
  }

  &--read-more {
    display: block;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;

    &::after {
      content: $fa-var-chevron-circle-right;
      font-family: 'FontAwesome';
      margin-left: 5px;
    }
  }

  &.button {
    @extend %button;

    @include mq($bp-small) {
      @include font-size(20);

      padding: $gutter / 2 $gutter;
    }

    &::after {
      margin-left: $gutter;
    }

    &.button--right {
      float: right;
    }

    &.button--hidden {
      display: none;
    }
  }

  &--recent-report {
    display: block;
    font-weight: bold;
    margin-bottom: $gutter * 2;
    text-decoration: none;

    .fa {
      margin-right: 5px;
    }
  }
}

.link--prices {
  color: $color-white;
  font-size: 90%;

  &:hover {
    color: $color-white;
    text-decoration: none;
  }
}

// WORDT ALLEEN NOG GEBRUIKT IN PROTOTYPE.
// VOOR LIVE VERSIE CHECK _sidebar-items.scss > .call-to-action LINK

.link--factsheet {
  background: $color-primary url('../images/logo-link.png') 20px 20px no-repeat;
  background-size: 28px 30px;
  color: $color-white;
  display: block;
  margin-bottom: 0;
  margin-left: -20px;
  margin-top: -20px;
  padding: $gutter;
  text-decoration: none;
  width: 100%;

  @include mq($bp-medium) {
    background-size: 56px 61px;
    min-height: 60px;
  }

  strong,
  span {
    display: block;
    padding-left: 40px;

    @include mq($bp-medium) {
      padding-left: 80px;
    }
  }

  strong {
    @include title-font();
    @include font-size(20);

    font-weight: 700;

    @include mq($bp-medium) {
      @include font-size(24);

      padding-top: 5px;
    }
  }

  &:hover {
    background-color: shade($color-primary, 25);
    color: $color-white;
  }
}

.search-result__hit {
  background: $color-status-info;
  padding: 3px;
  text-decoration: none;
}

// TOGGLE MENU

@if $mobile-menu == true {

  .toggle-menu {
    @include font-size(20);
    @include title-font;

    background: $color-primary;
    color: $color-white;
    height: 42px;
    line-height: 42px;
    padding: 0;
    position: absolute;
    right: 10px;
    text-align: center;
    text-decoration: none;
    top: 10px;
    width: 42px;

    @include mq($bp-small) {
      display: none;
    }

    &:hover {
      background: $color-secondary;
    }
  }
}
