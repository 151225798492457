.band.band--home {
  background-color: $color-white;

  .title--h1 {
    color: $color-black;
    margin-bottom: $gutter;
    text-transform: none;
  }

  .row {
    display: block;

    @include mq($bp-medium) {
      display: flex;
    }
  }

  .column {
    @include mq($bp-medium) {
      padding-bottom: $gutter * 2;
      padding-top: $gutter * 2;

      &--shift {
        margin-top: -9.4vw;
        padding-top: 0;

        @include mq(1600) {
          margin-top: -150px;
        }

        a {
          pointer-events: auto;
        }

        .figure {
          margin-bottom: -$gutter * 2;
          position: relative;
          top: -$gutter * 2;
          z-index: 1;

          img {
            margin-left: 0;
          }
        }
      }
    }

    @include mq($bp-large) {
      padding-bottom: $gutter * 2;
      padding-top: $gutter * 2;
    }
  }

  .figure {

    &__image {
      margin-left: 0;
    }
  }
}
