// global
$gutter: 20px;

// topmenu
$topmenu-height: 50px;
$topmenu-bar-height: 3px;

// logo
$logo-max-width: 340px;

// toggle button
$toggle-button-height: 32; // in px

// footer
$footer-logo-max-width: 160px;
$footer-item-size: 42; // in px > used for height search-input / social-media logo's
