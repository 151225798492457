.body--default .band.band--content {

  .band__content {
    @include mq($bp-medium) {

      &--flex {
        display: flex;
        justify-content: space-between;

        .content {
          flex: 1 1 57%; // {820px/1450px * 100};
          max-width: 820px;
        }

        .sidebar {
          flex: 1 1 38%; // {560px/1450px * 100}
          margin-left: $gutter;
          margin-top: -9.4vw;
          // max-width: 40%;

          @include mq($bp-large) {
            max-width: 560px;
            min-width: 300px;
          }

          @include mq(1600) {
            margin-top: -150px;
          }
        }
      }

      &:not(.band__content--flex) > *,
      .content > * {
        margin-bottom: $gutter * 2;
      }
    }

    &:not(.band__content--flex),
    .content {

      .title {
        clear: left;
        color: $color-black;
        margin-bottom: $gutter / 2;
        margin-top: $gutter * 2;

        &--h1 {
          margin-bottom: $gutter;

          @include mq($bp-medium) {
            margin-bottom: $gutter * 2;
          }

          @include mq($bp-large) {
            margin-bottom: $gutter * 3;
          }
        }

        &--h2 {

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
