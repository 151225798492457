.band--header {
  background-color: $color-secondary;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  transition: all 600ms ease;

  @include mq($bp-medium, max) {
    max-height: 1000px;
    overflow: hidden;
    transition: all 600ms ease;

    .show-menu & {
      max-height: 0;
    }
  }

  .band__background {
    padding-bottom: 50%;
    position: relative;

    @include mq($bp-medium) {
      padding-bottom: 455px / 1600px * 100%;
    }
  }

  .figure {
    margin-bottom: 0;
  }

  img {
    display: block;
    position: relative;
    width: 100%;
    z-index: 1;

    @include mq($bp-large, max) {
      visibility: hidden;
    }
  }

  .link {
    color: $color-white;
  }
}

.funds-title {
  // bottom: 20px;
  color: $color-white;
  display: none;
  left: -40px;
  position: relative;
  width: 100vw;
  z-index: 2;

  .title {
    @include title-font();

    color: $color-white;
    font-size: 7vw;
    font-weight: 700;
    line-height: .9;
    margin: 0 auto;
    max-width: 1450px;
    padding: 40px;
    position: relative;
    text-align: center;
    text-shadow: 0 0 20px transparentize($color-black, .8);
  }

  @include mq($bp-medium) {
    display: block;

    .title {
      @include font-size(64);
    }
  }

  @include mq($bp-large) {
    bottom: auto;

    .title {
      font-size: 7vw;
      line-height: .8;
    }
  }

  @include mq(1600) {
    width: 1680px;

    .title {
      @include font-size(108);
    }
  }
}
