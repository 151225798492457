html {
  // -webkit-font-smoothing: antialiased;
  font-size: 62.5%;
  height: 100%;
}

body {
  @include base-font();
  @include font-size(16);

  background: $color-body;
  color: $color-text;
  line-height: 1.8;
  margin: 0;
  overflow-x: hidden;
}

strong {
  font-weight: bold;
}

@import 'layout';
@import 'bands/';
