.band.band--copyright {
  @include font-size(14);

  background: $color-black;
  color: $color-white;
  text-align: center;

  .band__content {
    padding-top: 0;
  }
}
