.scroll-to-top {
  background-color: $color-primary;
  bottom: $gutter;
  color: $color-white;
  cursor: pointer;
  line-height: 1.4;
  opacity: 0;
  padding: 8px 13px;
  position: fixed;
  right: $gutter;
  transition: all 400ms ease;
  z-index: -1;

  @include mq($bp-large) {
    padding: 15px 20px;
  }

  &--active {
    display: block;
    opacity: .8;
    z-index: 10;
  }

  &:hover {
    opacity: 1;
  }
}
