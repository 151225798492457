.band.band--background {
  color: $color-white;
  min-height: 35vh;

  .column {
    @include mq($bp-medium) {
      padding-bottom: $gutter * 2;
      padding-top: $gutter * 2;
    }

    &:first-child {
      flex: 0 1 40%;
    }

    .title {
      @include font-size(28);

      color: $color-white;
      text-transform: uppercase;

      @include mq($bp-small) {
        @include font-size(34);
      }

      @include mq($bp-medium) {
        @include font-size(40);
      }

      @include mq($bp-large) {
        @include font-size(48);
      }
    }

    .paragraph {
      @include font-size(16);

      max-width: 600px;

      @include mq($bp-medium) {
        @include font-size(19);
      }

      @include mq($bp-large) {
        @include font-size(22);
      }
    }

    .link {
      color: $color-white;

      &--read-more {

        &:hover {
          color: $color-primary;
        }

        &::after {
          color: $color-primary;
        }
      }
    }
  }
}
