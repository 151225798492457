// default colors
$color-black:             #000000;
$color-grey-darker:       #181818;
$color-grey-dark:         #252525;
$color-grey:              #999999;
$color-grey-light:        #d4d4d4;
$color-grey-lighter:      #f7f7f7;
$color-white:             #ffffff;

$color-social-twitter:    #35ccff;
$color-social-facebook:   #03539e;
$color-social-linkedin:   #217bc1;

$color-status-error:      #e41b13;
$color-status-ok:         #7ab317;
$color-status-info:       #fff2af;

// custom colors
$color-primary:           #00aacd;
$color-secondary:         #2c3b47;
$color-tertiary:          #ff8500;

@if $website == intereffekt {
  $color-primary:           #ff8500;
  $color-secondary:         $color-black;
  $color-tertiary:          #ff8500;
}

$color-body:              $color-grey-light;
$color-text:              $color-grey-darker;
$color-hover:             darken($color-primary, 8);
