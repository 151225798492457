.row {
  box-sizing: border-box;

  @include mq($bp-small) {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    justify-content: center;
  }

  &--padding {
    padding: $gutter 0;
  }
}

.columns {
  display: flex;
  flex-wrap: wrap;
  padding-top: $gutter;

  &--gutter {
    left: 50%;
    position: relative;
    transform: translateX(-50%);
  }
}

.column {
  box-sizing: border-box;
  margin-bottom: $gutter;
  position: relative;

  @include mq($bp-medium) {
    flex: 0 1 60%;
    margin-bottom: 0;
    max-width: 60%;
    // min-width: 500px;

    &--small {
      flex: 0 1 400px;

      &--left {
        padding-right: $gutter;

        @include mq($bp-large) {
          padding-right: $gutter * 2;
        }
      }

      &--right {
        padding-left: $gutter;

        @include mq($bp-large) {
          padding-left: $gutter * 2;
        }
      }
    }
  }

  // .columns--4 & {
  //   flex: 1 1 100%;
  //   max-width: 100%;

  //   @include mq($bp-small) {
  //     flex: 1 1 50%;
  //     max-width: 50%;

  //     @include mq($bp-large, max) {

  //       &--list {
  //         flex: 1 1 33%;
  //         max-width: 33%;
  //       }

  //       &:last-child {
  //         flex: 1 1 100%;
  //         max-width: 100%;

  //         .column__content {
  //           box-sizing: border-box;
  //           display: flex;
  //           flex-wrap: nowrap;
  //         }
  //       }
  //     }
  //   }

  //   @include mq($bp-large) {
  //     flex: 1 1 25%;
  //     max-width: 25%;
  //   }
  // }

  &--vcenter {
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3vw $gutter;
  }

  &__content {
    position: relative;
    z-index: 2;

    &--centered {
      text-align: center;
    }

    &--white {
      color: $color-white;
    }

    &--padding {
      @include mq($bp-small) {
        padding: 0 $gutter;
      }

      @include mq($bp-large) {
        padding: 0 ($gutter * 2);
      }
    }
  }

  &__background,
  &__link {
    background-position: center;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }

  &__link {
    display: block;
    text-align: center;
    z-index: 3;
  }
}
