@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  font-size: 62.5%;
  height: 100%; }

body {
  font-family: "open-sans", sans-serif;
  font-weight: 300;
  line-height: 1.5;
  font-size: 16px;
  font-size: 1.6rem;
  background: #d4d4d4;
  color: #181818;
  line-height: 1.8;
  margin: 0;
  overflow-x: hidden; }

strong {
  font-weight: bold; }

.site {
  background: #ffffff;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1680px;
  min-height: 100vh;
  min-width: 300px;
  overflow: hidden;
  position: relative; }
  @media screen and (min-width: 1682px) {
    .site {
      border-left: 1px solid #bfbfbf;
      border-right: 1px solid #bfbfbf; } }

@media screen and (max-width: 599px) {
  .hide-on-mobile {
    display: none !important;
    /* stylelint-disable-line declaration-no-important */ } }

@media screen and (min-width: 600px) {
  .hide-on-desktop {
    display: none !important;
    /* stylelint-disable-line declaration-no-important */ } }

.band {
  min-width: 300px;
  position: relative; }
  .band__content {
    margin: 0 auto;
    max-width: 1490px;
    padding: 20px;
    position: relative;
    z-index: 2; }
  .band__background {
    background-position: center;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0; }

.band--footer {
  font-size: 14px;
  font-size: 1.4rem;
  background: #000000;
  color: #ffffff;
  font-weight: normal; }
  .band--footer .title {
    color: #ffffff;
    margin-bottom: 20px; }
  .band--footer a,
  .band--footer .link {
    color: #ffffff;
    text-decoration: none; }
    .band--footer a:hover,
    .band--footer .link:hover {
      color: #00aacd; }
  .band--footer .fa {
    font-size: 150%; }
  .band--footer .figure {
    margin-left: 0;
    width: 300px; }
    .band--footer .figure img {
      margin-left: 0;
      width: 100%; }
  .band--footer .columns--4 .column {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%; }
    @media screen and (max-width: 599px) {
      .band--footer .columns--4 .column {
        margin-bottom: 0; }
        .band--footer .columns--4 .column .title--spacer {
          display: none; } }

@media screen and (min-width: 600px) and (max-width: 1023px) {
  .band--footer .columns--4 .column {
    margin-right: 5%;
    max-width: 28.33%;
    width: 28.33%; }
    .band--footer .columns--4 .column:nth-child(3n) {
      margin-right: 0; } }
  @media screen and (min-width: 1024px) {
    .band--footer .columns--4 .column {
      margin-right: 3.33%;
      max-width: 22.5%;
      width: 22.5%; }
      .band--footer .columns--4 .column:nth-child(4n) {
        margin-right: 0; } }

.band.band--copyright {
  font-size: 14px;
  font-size: 1.4rem;
  background: #000000;
  color: #ffffff;
  text-align: center; }
  .band.band--copyright .band__content {
    padding-top: 0; }

@media screen and (min-width: 600px) and (max-width: 1023px) {
  .social {
    flex: 1 1 33%;
    max-width: 33%; }
    .social > * {
      margin-left: 20px !important;
      /* stylelint-disable-line declaration-no-important */ } }

.social__list {
  margin: 0; }
  .social__list li {
    display: inline-block;
    font-size: 0;
    margin-right: 5px; }
    .social__list li a {
      font-size: 42px;
      font-size: 4.2rem;
      color: #00aacd;
      display: block;
      line-height: 42px;
      text-decoration: none; }

.band.band--about {
  font-size: 16px;
  font-size: 1.6rem;
  background-color: rgba(247, 247, 247, 0.5);
  padding-bottom: 20px;
  padding-top: 20px; }
  .band.band--about h3 {
    color: #000000;
    margin-bottom: 20px;
    text-transform: uppercase; }

.band.band--background {
  color: #ffffff;
  min-height: 35vh; }
  @media screen and (min-width: 768px) {
    .band.band--background .column {
      padding-bottom: 40px;
      padding-top: 40px; } }
  .band.band--background .column:first-child {
    flex: 0 1 40%; }
  .band.band--background .column .title {
    font-size: 28px;
    font-size: 2.8rem;
    color: #ffffff;
    text-transform: uppercase; }
    @media screen and (min-width: 600px) {
      .band.band--background .column .title {
        font-size: 34px;
        font-size: 3.4rem; } }
    @media screen and (min-width: 768px) {
      .band.band--background .column .title {
        font-size: 40px;
        font-size: 4rem; } }
    @media screen and (min-width: 1024px) {
      .band.band--background .column .title {
        font-size: 48px;
        font-size: 4.8rem; } }
  .band.band--background .column .paragraph {
    font-size: 16px;
    font-size: 1.6rem;
    max-width: 600px; }
    @media screen and (min-width: 768px) {
      .band.band--background .column .paragraph {
        font-size: 19px;
        font-size: 1.9rem; } }
    @media screen and (min-width: 1024px) {
      .band.band--background .column .paragraph {
        font-size: 22px;
        font-size: 2.2rem; } }
  .band.band--background .column .link {
    color: #ffffff; }
    .band.band--background .column .link--read-more:hover {
      color: #00aacd; }
    .band.band--background .column .link--read-more::after {
      color: #00aacd; }

.band.band--blog {
  background-color: rgba(247, 247, 247, 0.5);
  padding-bottom: 30px;
  padding-top: 30px;
  text-align: center; }
  .band.band--blog .band__content > .title {
    margin-bottom: 20px; }
  .band.band--blog .band__content > .paragraph {
    margin-bottom: 40px; }

@media screen and (min-width: 600px) {
  .band.band--certificates {
    padding-bottom: 40px;
    padding-top: 40px; } }

.row {
  box-sizing: border-box; }
  @media screen and (min-width: 600px) {
    .row {
      align-content: stretch;
      align-items: stretch;
      display: flex;
      justify-content: center; } }
  .row--padding {
    padding: 20px 0; }

.columns {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px; }
  .columns--gutter {
    left: 50%;
    position: relative;
    transform: translateX(-50%); }

.column {
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative; }
  @media screen and (min-width: 768px) {
    .column {
      flex: 0 1 60%;
      margin-bottom: 0;
      max-width: 60%; }
      .column--small {
        flex: 0 1 400px; }
        .column--small--left {
          padding-right: 20px; } }
      @media screen and (min-width: 768px) and (min-width: 1024px) {
        .column--small--left {
          padding-right: 40px; } }
  @media screen and (min-width: 768px) {
        .column--small--right {
          padding-left: 20px; } }
      @media screen and (min-width: 768px) and (min-width: 1024px) {
        .column--small--right {
          padding-left: 40px; } }
  .column--vcenter {
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3vw 20px; }
  .column__content {
    position: relative;
    z-index: 2; }
    .column__content--centered {
      text-align: center; }
    .column__content--white {
      color: #ffffff; }
    @media screen and (min-width: 600px) {
      .column__content--padding {
        padding: 0 20px; } }
    @media screen and (min-width: 1024px) {
      .column__content--padding {
        padding: 0 40px; } }
  .column__background, .column__link {
    background-position: center;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0; }
  .column__link {
    display: block;
    text-align: center;
    z-index: 3; }

@media screen and (min-width: 768px) {
  .body--default .band.band--content .band__content--flex {
    display: flex;
    justify-content: space-between; }
    .body--default .band.band--content .band__content--flex .content {
      flex: 1 1 57%;
      max-width: 820px; }
    .body--default .band.band--content .band__content--flex .sidebar {
      flex: 1 1 38%;
      margin-left: 20px;
      margin-top: -9.4vw; } }
    @media screen and (min-width: 768px) and (min-width: 1024px) {
      .body--default .band.band--content .band__content--flex .sidebar {
        max-width: 560px;
        min-width: 300px; } }
    @media screen and (min-width: 768px) and (min-width: 1600px) {
      .body--default .band.band--content .band__content--flex .sidebar {
        margin-top: -150px; } }

@media screen and (min-width: 768px) {
  .body--default .band.band--content .band__content:not(.band__content--flex) > *,
  .body--default .band.band--content .band__content .content > * {
    margin-bottom: 40px; } }

.body--default .band.band--content .band__content:not(.band__content--flex) .title,
.body--default .band.band--content .band__content .content .title {
  clear: left;
  color: #000000;
  margin-bottom: 10px;
  margin-top: 40px; }
  .body--default .band.band--content .band__content:not(.band__content--flex) .title--h1,
  .body--default .band.band--content .band__content .content .title--h1 {
    margin-bottom: 20px; }
    @media screen and (min-width: 768px) {
      .body--default .band.band--content .band__content:not(.band__content--flex) .title--h1,
      .body--default .band.band--content .band__content .content .title--h1 {
        margin-bottom: 40px; } }
    @media screen and (min-width: 1024px) {
      .body--default .band.band--content .band__content:not(.band__content--flex) .title--h1,
      .body--default .band.band--content .band__content .content .title--h1 {
        margin-bottom: 60px; } }
  .body--default .band.band--content .band__content:not(.band__content--flex) .title--h2:first-child,
  .body--default .band.band--content .band__content .content .title--h2:first-child {
    margin-top: 0; }

.band--header {
  background-color: #2c3b47;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  transition: all 600ms ease; }
  @media screen and (max-width: 767px) {
    .band--header {
      max-height: 1000px;
      overflow: hidden;
      transition: all 600ms ease; }
      .show-menu .band--header {
        max-height: 0; } }
  .band--header .band__background {
    padding-bottom: 50%;
    position: relative; }
    @media screen and (min-width: 768px) {
      .band--header .band__background {
        padding-bottom: 28.4375%; } }
  .band--header .figure {
    margin-bottom: 0; }
  .band--header img {
    display: block;
    position: relative;
    width: 100%;
    z-index: 1; }
    @media screen and (max-width: 1023px) {
      .band--header img {
        visibility: hidden; } }
  .band--header .link {
    color: #ffffff; }

.funds-title {
  color: #ffffff;
  display: none;
  left: -40px;
  position: relative;
  width: 100vw;
  z-index: 2; }
  .funds-title .title {
    font-family: "prometo", sans-serif;
    font-weight: bold;
    line-height: 1.2;
    color: #ffffff;
    font-size: 7vw;
    font-weight: 700;
    line-height: .9;
    margin: 0 auto;
    max-width: 1450px;
    padding: 40px;
    position: relative;
    text-align: center;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.2); }
  @media screen and (min-width: 768px) {
    .funds-title {
      display: block; }
      .funds-title .title {
        font-size: 64px;
        font-size: 6.4rem; } }
  @media screen and (min-width: 1024px) {
    .funds-title {
      bottom: auto; }
      .funds-title .title {
        font-size: 7vw;
        line-height: .8; } }
  @media screen and (min-width: 1600px) {
    .funds-title {
      width: 1680px; }
      .funds-title .title {
        font-size: 108px;
        font-size: 10.8rem; } }

.band.band--home {
  background-color: #ffffff; }
  .band.band--home .title--h1 {
    color: #000000;
    margin-bottom: 20px;
    text-transform: none; }
  .band.band--home .row {
    display: block; }
    @media screen and (min-width: 768px) {
      .band.band--home .row {
        display: flex; } }
  @media screen and (min-width: 768px) {
    .band.band--home .column {
      padding-bottom: 40px;
      padding-top: 40px; }
      .band.band--home .column--shift {
        margin-top: -9.4vw;
        padding-top: 0; } }
    @media screen and (min-width: 768px) and (min-width: 1600px) {
      .band.band--home .column--shift {
        margin-top: -150px; } }
  @media screen and (min-width: 768px) {
        .band.band--home .column--shift a {
          pointer-events: auto; }
        .band.band--home .column--shift .figure {
          margin-bottom: -40px;
          position: relative;
          top: -40px;
          z-index: 1; }
          .band.band--home .column--shift .figure img {
            margin-left: 0; } }
  @media screen and (min-width: 1024px) {
    .band.band--home .column {
      padding-bottom: 40px;
      padding-top: 40px; } }
  .band.band--home .figure__image {
    margin-left: 0; }

.band--logo {
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 3; }
  .body--home .band--logo .band__content {
    max-width: none; }
  .body--home .band--logo .logo {
    position: relative; }
    @media screen and (min-width: 600px) {
      .body--home .band--logo .logo {
        left: 20px; } }

.logo {
  display: block;
  max-width: 340px;
  width: 180px; }
  @media screen and (min-width: 600px) {
    .logo {
      width: 30vw; } }
  .logo img {
    display: block;
    width: 100%; }

.toggle {
  color: #ffffff;
  display: flex;
  position: absolute;
  right: 20px;
  text-decoration: none;
  top: 20px; }
  @media screen and (min-width: 768px) {
    .toggle {
      top: 50%;
      transform: translateY(-50%); } }
  .toggle span {
    display: none; }
    @media screen and (min-width: 768px) {
      .toggle span {
        font-size: 16px;
        font-size: 1.6rem;
        display: inline-block;
        line-height: 32px;
        margin-right: 10px; } }
  .toggle i {
    font-size: 32px;
    font-size: 3.2rem; }
  .toggle:hover {
    opacity: .8; }

.band--maps {
  background-color: #f7f7f7; }
  .band--maps .google-maps {
    min-height: 260px; }
    @media screen and (min-width: 768px) {
      .band--maps .google-maps {
        min-height: 400px; } }
    @media screen and (min-width: 1024px) {
      .band--maps .google-maps {
        min-height: 500px; } }
    @media screen and (min-width: 1280px) {
      .band--maps .google-maps {
        min-height: 580px; } }
    .band--maps .google-maps__window {
      background-color: rgba(0, 170, 205, 0.8);
      color: #000000;
      display: none;
      padding: 20px; }
      @media screen and (min-width: 1024px) {
        .band--maps .google-maps__window {
          left: 20px;
          min-width: 260px;
          position: absolute;
          top: 20px; } }
      @media screen and (min-width: 1280px) {
        .band--maps .google-maps__window {
          left: auto;
          min-width: 350px;
          padding: 40px;
          position: absolute;
          right: 40px;
          top: 40px; } }
      .band--maps .google-maps__window--active {
        display: block; }
      .band--maps .google-maps__window .title {
        color: #000000; }
        .band--maps .google-maps__window .title--h2 {
          font-size: 40px;
          font-size: 4rem;
          border-bottom: 1px solid #000000;
          margin-bottom: 20px;
          padding-bottom: 10px;
          text-transform: uppercase; }
  .band--maps p {
    margin-bottom: 0; }

.band--menu {
  background-color: #2c3b47;
  color: #ffffff;
  max-height: 0;
  overflow: hidden;
  position: relative;
  transition: all 300ms ease; }
  @media screen and (max-width: 767px) {
    .show-menu .band--menu {
      max-height: none;
      padding-top: 150px; } }

.band--newsletter {
  background: #2c3b47;
  background-attachment: fixed;
  background-image: url("../images/pattern.jpg");
  background-position: 50%;
  background-size: 145px 174px;
  padding-top: 20px; }
  .band--newsletter .band__content {
    color: #ffffff;
    text-align: center; }
    .band--newsletter .band__content .title {
      color: #ffffff; }

.newsletter__form {
  border-radius: 5px;
  height: 42px;
  margin: 0 auto 30px;
  max-width: 600px;
  overflow: hidden;
  position: relative;
  width: 80%; }
  .newsletter__form input {
    font-size: 14px;
    font-size: 1.4rem;
    font-family: "open-sans", sans-serif;
    font-weight: 300;
    line-height: 1.5;
    border: 0;
    box-sizing: border-box;
    height: 42px;
    line-height: 42px;
    padding: 0 32px 0 5px;
    width: 100%; }
    @media screen and (min-width: 600px) {
      .newsletter__form input {
        font-size: 16px;
        font-size: 1.6rem; } }
    .newsletter__form input:focus {
      outline: none; }
    .newsletter__form input.error + button {
      background: #e41b13;
      color: #ffffff; }
  .newsletter__form button {
    font-size: 21px;
    font-size: 2.1rem;
    -webkit-appearance: none;
    /* stylelint-disable-line property-no-vendor-prefix */
    background: #00aacd;
    border: 0;
    color: #ffffff;
    cursor: pointer;
    height: 42px;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 42px; }
    .newsletter__form button:hover {
      background-color: #0088a4;
      color: #ffffff; }
    .newsletter__form button:focus {
      outline: none; }

.band.band--rating {
  font-size: 22px;
  font-size: 2.2rem;
  text-align: center; }
  @media screen and (min-width: 768px) {
    .band.band--rating {
      font-size: 27px;
      font-size: 2.7rem; } }
  .band.band--rating .band__content {
    padding-bottom: 40px;
    padding-top: 40px; }
    @media screen and (min-width: 600px) {
      .band.band--rating .band__content {
        padding-bottom: 80px;
        padding-top: 80px; } }
  .band.band--rating .paragraph {
    margin-left: auto;
    margin-right: auto;
    max-width: 475px; }
    .band.band--rating .paragraph strong {
      color: #00aacd;
      font-size: 120%;
      font-weight: bold; }
    .band.band--rating .paragraph ~ .paragraph {
      font-size: 20px;
      font-size: 2rem;
      max-width: 650px; }
      @media screen and (min-width: 768px) {
        .band.band--rating .paragraph ~ .paragraph {
          font-size: 24px;
          font-size: 2.4rem; } }
  .band.band--rating .title {
    font-size: 26px;
    font-size: 2.6rem;
    text-transform: uppercase; }
    @media screen and (min-width: 768px) {
      .band.band--rating .title {
        font-size: 30px;
        font-size: 3rem; } }
  .band.band--rating .rating {
    font-size: 32px;
    font-size: 3.2rem;
    color: #00aacd;
    margin-bottom: 40px; }
    @media screen and (min-width: 768px) {
      .band.band--rating .rating {
        font-size: 41px;
        font-size: 4.1rem; } }

.band.band--read-more {
  border-top: 1px solid rgba(44, 59, 71, 0.25);
  padding-top: 40px; }
  .band.band--read-more .link:not(.button) {
    border-bottom: 1px dashed #2c3b47;
    text-decoration: none; }
  .band.band--read-more .title {
    color: #000000; }
  .band.band--read-more .button {
    margin-right: 20px; }
    .band.band--read-more .button .fa {
      margin-right: 20px; }

.band.band--services .title--h2 {
  font-size: 28px;
  font-size: 2.8rem;
  color: #000000;
  font-weight: normal;
  margin-bottom: 20px; }
  @media screen and (min-width: 600px) {
    .band.band--services .title--h2 {
      font-size: 34px;
      font-size: 3.4rem; } }
  @media screen and (min-width: 768px) {
    .band.band--services .title--h2 {
      font-size: 40px;
      font-size: 4rem; } }
  @media screen and (min-width: 1024px) {
    .band.band--services .title--h2 {
      font-size: 48px;
      font-size: 4.8rem; } }

.band.band--services .title--h3 {
  font-size: 24px;
  font-size: 2.4rem;
  color: #00aacd; }
  @media screen and (min-width: 768px) {
    .band.band--services .title--h3 {
      font-size: 26px;
      font-size: 2.6rem; } }
  @media screen and (min-width: 1024px) {
    .band.band--services .title--h3 {
      font-size: 28px;
      font-size: 2.8rem; } }

.band.band--services .paragraph {
  font-size: 90%;
  max-width: 90%; }
  @media screen and (min-width: 768px) {
    .band.band--services .paragraph {
      font-size: 100%; } }

.band.band--services .link--read-more {
  color: #000000; }
  .band.band--services .link--read-more:hover {
    color: #00aacd; }
    .band.band--services .link--read-more:hover::after {
      color: #00aacd; }
  .band.band--services .link--read-more::after {
    color: #2c3b47; }

.band.band--services img {
  margin-bottom: 20px;
  width: 100%; }

@media screen and (max-width: 599px) {
  .band.band--services .column:first-child {
    border-bottom: 1px solid #f7f7f7;
    margin-bottom: 20px; } }

.band.band--team-background {
  font-size: 16px;
  font-size: 1.6rem;
  color: #ffffff;
  text-align: center; }
  @media screen and (min-width: 768px) {
    .band.band--team-background {
      font-size: 21px;
      font-size: 2.1rem;
      min-height: 300px; } }
  .band.band--team-background .band__content {
    max-width: 650px; }
    @media screen and (min-width: 768px) {
      .band.band--team-background .band__content {
        padding-bottom: 40px;
        padding-top: 40px; } }
  .band.band--team-background .title--h2 {
    font-size: 28px;
    font-size: 2.8rem;
    color: #ffffff;
    text-transform: uppercase; }
    @media screen and (min-width: 600px) {
      .band.band--team-background .title--h2 {
        font-size: 34px;
        font-size: 3.4rem; } }
    @media screen and (min-width: 768px) {
      .band.band--team-background .title--h2 {
        font-size: 40px;
        font-size: 4rem; } }
    @media screen and (min-width: 1024px) {
      .band.band--team-background .title--h2 {
        font-size: 48px;
        font-size: 4.8rem; } }
  .band.band--team-background .link {
    color: #ffffff; }
    .band.band--team-background .link:hover {
      color: #00aacd; }
    .band.band--team-background .link--read-more::after {
      color: #00aacd; }

@media screen and (min-width: 768px) {
  .band.band--team-members {
    margin-top: -40px; } }

@media screen and (min-width: 1024px) {
  .band.band--team-members {
    margin-top: -60px; } }

.band--top {
  height: 50px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
  background: rgba(0, 0, 0, 0.25); }
  @media screen and (min-width: 768px) {
    .band--top {
      display: block; } }
  .band--top .band__content {
    padding: 0;
    text-align: right; }
    .band--top .band__content::before, .band--top .band__content::after {
      content: '';
      display: table; }
    .band--top .band__content::after {
      clear: both; }

.logo-holder {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 0;
  justify-content: center;
  text-align: center; }
  .logo-holder .logo-img {
    display: inline-block;
    margin: 0 10px 20px;
    max-height: 100px;
    max-width: 40vw; }
    .logo-holder .logo-img a {
      display: block; }
    .logo-holder .logo-img img {
      display: block;
      max-height: 100px;
      max-width: 100%; }

.menu.menu--legal {
  display: block; }
  @media screen and (min-width: 600px) {
    .menu.menu--legal {
      display: inline-block; } }
  .menu.menu--legal .menu__item {
    display: inline-block;
    margin-left: 10px; }
    .menu.menu--legal .menu__item::before {
      margin-right: 10px; }
    @media screen and (max-width: 599px) {
      .menu.menu--legal .menu__item:first-child::before {
        content: ''; } }
  .menu.menu--legal .menu__link {
    color: #ffffff;
    text-decoration: none;
    transition: all 200ms ease; }
    .menu.menu--legal .menu__link:hover {
      color: #00aacd; }

.menu--main {
  color: #ffffff;
  display: flex;
  line-height: 1.2; }
  @media screen and (min-width: 600px) {
    .menu--main {
      padding: 20px; } }
  .menu--main .menu__list {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    @media screen and (min-width: 1024px) {
      .menu--main .menu__list {
        flex-wrap: nowrap; } }
    .menu--main .menu__list > .menu__item {
      flex: 1 1 100%; }
    .menu--main .menu__list .menu__list {
      display: block;
      margin-bottom: 20px;
      margin-top: 10px; }
      @media screen and (min-width: 1024px) {
        .menu--main .menu__list .menu__list {
          margin-bottom: 0; } }
      .menu--main .menu__list .menu__list .menu__list {
        list-style: disc;
        margin-bottom: 5px;
        margin-left: 20px; }
  .menu--main .menu__item {
    margin-bottom: 10px; }
  .menu--main .menu__link {
    color: #ffffff;
    text-decoration: none;
    transition: 200ms all ease; }
    .menu--main .menu__link:hover {
      color: rgba(255, 255, 255, 0.8); }
  .menu--main .title {
    font-size: 25px;
    font-size: 2.5rem;
    color: #ffffff; }

.mobile {
  background: #00aacd;
  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  transform: translate(-100%, 0);
  transition: transform 0.3s cubic-bezier(0.05, 0.45, 0.35, 0.95);
  width: 320px;
  z-index: -1; }
  @media screen and (min-width: 768px) {
    .mobile {
      display: none; } }
  .show-menu .mobile {
    transform: translate(0, 0);
    z-index: 2; }
  .mobile__wrapper {
    height: calc(100% - 50px);
    margin: 50px auto 0;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    width: 280px; }
    .no-csscalc .mobile__wrapper {
      height: 100%; }
    .mobile__wrapper::-webkit-scrollbar {
      width: 0; }
  .mobile .toggle-menu {
    background: #00809a;
    position: absolute;
    right: 20px;
    top: 10px; }
  .mobile-menu {
    list-style: none;
    transition: all 0.25s cubic-bezier(0.05, 0.45, 0.35, 0.95); }
    .mobile-menu.close {
      margin-left: -100%; }
    .mobile-menu--main {
      border-top: 1px solid #0095b4;
      margin-top: 20px; }
    .mobile-menu--sub {
      left: 100%;
      position: absolute;
      top: 0; }
      .mobile-menu--sub li:last-child {
        margin-bottom: 20px; }
      .mobile-menu--sub.show {
        height: auto;
        left: 0; }
        .mobile-menu--sub.show ul {
          left: 100%; }
      .mobile-menu--sub.close {
        left: 0%; }
        .mobile-menu--sub.close .show {
          left: 100%; }
    .mobile-menu__item {
      clear: left; }
      .mobile-menu__item--header a {
        background: #00809a;
        color: #ffffff;
        margin-top: 20px;
        padding: 10px 40px;
        text-align: center; }
        .mobile-menu__item--header a span {
          bottom: 0;
          left: 0;
          position: absolute;
          text-align: center;
          top: 0; }
          .mobile-menu__item--header a span i {
            left: 10px;
            margin-top: -8px;
            position: absolute;
            top: 50%; }
    .mobile-menu__link {
      font-size: 14px;
      font-size: 1.4rem;
      border-bottom: 1px solid #0095b4;
      color: #ffffff;
      display: block;
      font-family: "open-sans", sans-serif;
      font-weight: bold;
      line-height: 1.2;
      padding: 10px;
      position: relative;
      text-decoration: none; }
      .mobile-menu__link--parent {
        padding-right: 40px; }
        .mobile-menu__link--parent .open-sub {
          background: #00809a;
          border-radius: 50%;
          height: 30px;
          margin-top: -15px;
          position: absolute;
          right: 0;
          text-align: center;
          top: 50%;
          width: 30px; }
          .mobile-menu__link--parent .open-sub i {
            color: #ffffff;
            left: 50%;
            margin-left: -4px;
            margin-top: -7px;
            position: absolute;
            top: 50%; }

.topmenu {
  display: inline;
  float: right; }
  .topmenu::before, .topmenu::after {
    content: '';
    display: table; }
  .topmenu::after {
    clear: both; }
  @media screen and (min-width: 1490px) {
    .topmenu {
      border-right: 1px solid rgba(255, 255, 255, 0.3); } }
  .topmenu__item {
    display: none;
    float: left;
    position: relative; }
    @media screen and (min-width: 768px) {
      .topmenu__item {
        display: inline; } }
    .topmenu__item.hover, .topmenu__item:hover {
      visibility: visible; }
      .topmenu__item.hover > ul, .topmenu__item:hover > ul {
        visibility: visible; }
    .topmenu__item--search, .topmenu__item--lang, .topmenu__item--toggle {
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      display: inline; }
      .topmenu__item--search a::after, .topmenu__item--lang a::after, .topmenu__item--toggle a::after {
        display: none; }
      .topmenu__item--search a:hover, .topmenu__item--lang a:hover, .topmenu__item--toggle a:hover {
        background-color: rgba(0, 170, 205, 0.2); }
    .topmenu__item--lang .fa {
      padding-left: .3em; }
    @media screen and (min-width: 768px) {
      .topmenu__item--toggle {
        display: none; } }
    .topmenu__item--toggle .fa-close {
      display: none; }
      .show-menu .topmenu__item--toggle .fa-close {
        display: inline-block; }
    .show-menu .topmenu__item--toggle .fa-bars {
      display: none; }
    .topmenu__item ul {
      left: 0;
      position: absolute;
      right: 0;
      top: 100%;
      visibility: hidden; }
      .topmenu__item ul li {
        display: block;
        float: left;
        width: 100%; }
        .topmenu__item ul li a {
          background: #ffffff;
          color: #2c3b47; }
          .topmenu__item ul li a:hover {
            background: #ffffff;
            color: #00aacd; }
  .topmenu a {
    font-size: 16px;
    font-size: 1.6rem;
    color: #ffffff;
    display: block;
    height: 50px;
    line-height: 50px;
    min-width: 30px;
    overflow: hidden;
    padding: 0 13.33333px;
    position: relative;
    text-align: center;
    text-decoration: none;
    white-space: nowrap; }
    @media screen and (max-width: 599px) {
      .topmenu a {
        padding: 0 10px;
        width: 30px; } }
    @media screen and (min-width: 1024px) {
      .topmenu a {
        font-size: 18px;
        font-size: 1.8rem; } }
    .topmenu a::after {
      background: #00aacd;
      bottom: 0;
      content: '';
      height: 3px;
      left: 13.33333px;
      position: absolute;
      right: 13.33333px;
      transform: translateY(3px);
      transition: transform 200ms ease; }
    .topmenu a:hover::after {
      transform: translateY(-7px); }
  .topmenu__item--selected a {
    color: #00aacd; }
  @media screen and (max-width: 767px) {
    .topmenu__item--search a,
    .topmenu__item--toggle a {
      font-size: 24px;
      font-size: 2.4rem; } }

.article {
  border-bottom: 1px solid #f7f7f7;
  margin-bottom: 10px;
  padding-bottom: 10px; }
  .article::before, .article::after {
    content: '';
    display: table; }
  .article::after {
    clear: both; }
  @media screen and (min-width: 600px) {
    .article {
      margin-bottom: 20px;
      padding-bottom: 20px; } }
  .lt-ie9 .article::before, .lt-ie9 .article::after {
    margin: 0 !important;
    /* stylelint-disable-line declaration-no-important */
    padding: 0 !important;
    /* stylelint-disable-line declaration-no-important */ }
  .article--first {
    border-top: 1px solid #f7f7f7;
    padding-top: 10px; }
    @media screen and (min-width: 600px) {
      .article--first {
        padding-top: 20px; } }
  .article--last {
    margin-bottom: 0; }
  @media screen and (min-width: 480px) {
    .article__content--image {
      display: inline;
      float: left;
      width: 70%; } }
  .article__image {
    display: block;
    margin: 0 auto;
    max-width: 100%; }
    @media screen and (min-width: 480px) {
      .article__image {
        width: 100%; } }
    .article__image-link {
      display: block;
      margin-bottom: 10px;
      text-align: center;
      text-decoration: none; }
      @media screen and (min-width: 480px) {
        .article__image-link {
          display: inline;
          float: left;
          margin-right: 2%;
          width: 28%; } }
  .article__date, .article__location {
    color: #999999;
    display: block;
    margin-bottom: 10px; }

.detail-info {
  color: #999999;
  line-height: 1.5;
  margin-bottom: 10px;
  position: relative;
  top: -10px; }
  .detail-info span {
    display: block; }
    .detail-info span span {
      display: none; }
      @media screen and (min-width: 600px) {
        .detail-info span span {
          display: inline; } }

.blog-detail .figure--left {
  float: none;
  margin: 0 auto 20px; }
  @media screen and (min-width: 600px) {
    .blog-detail .figure--left {
      display: block;
      margin-bottom: 20px !important;
      /* stylelint-disable-line declaration-no-important */
      max-width: 100% !important;
      /* stylelint-disable-line declaration-no-important */ } }
  @media screen and (min-width: 600px) and (max-width: 767px) {
    .blog-detail .figure--left {
      display: inline;
      float: left;
      margin-right: 20px;
      max-width: 40% !important;
      /* stylelint-disable-line declaration-no-important */ } }
  @media screen and (min-width: 1024px) {
    .blog-detail .figure--left {
      display: inline;
      float: left;
      margin-right: 20px;
      max-width: 40% !important;
      /* stylelint-disable-line declaration-no-important */ } }

.blog {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 20px;
  max-width: 300px; }
  .no-flexbox .blog {
    display: block; }
    .no-flexbox .blog::before, .no-flexbox .blog::after {
      content: '';
      display: table; }
    .no-flexbox .blog::after {
      clear: both; }
  @media screen and (min-width: 600px) {
    .blog {
      align-items: stretch;
      max-width: 700px; } }
  @media screen and (min-width: 1024px) {
    .blog {
      max-width: 1400px; } }
  .blog .blog-item {
    font-size: 16px;
    font-size: 1.6rem;
    background-color: #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    color: inherit;
    margin-bottom: 20px;
    position: relative;
    text-decoration: none;
    top: 0;
    transition: top 300ms ease, box-shadow 300ms ease; }
    .blog .blog-item:hover {
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.35); }
    .blog .blog-item:nth-child(3n), .blog .blog-item:nth-child(4n) {
      display: none; }

@media screen and (min-width: 600px) and (max-width: 1023px) {
  .blog .blog-item {
    flex: 0 0 47.5%;
    margin-right: 5%;
    max-width: 47.5%; }
    .blog .blog-item:nth-child(2n) {
      margin-right: 0; }
    .blog .blog-item:hover {
      top: -10px; }
    .no-flexbox .blog .blog-item {
      display: inline;
      float: left;
      width: 47.5%; }
      .no-flexbox .blog .blog-item:nth-child(3n), .no-flexbox .blog .blog-item:nth-child(4n) {
        display: none; } }
    @media screen and (min-width: 1024px) {
      .blog .blog-item {
        flex: 0 0 30%;
        margin-bottom: 0;
        margin-right: 5%;
        max-width: 30%; }
        .blog .blog-item:nth-child(3n) {
          display: block;
          margin-right: 0; }
        .no-flexbox .blog .blog-item {
          display: inline;
          float: left;
          width: 30%; }
          .no-flexbox .blog .blog-item:nth-child(4n) {
            display: none; } }
    @media screen and (min-width: 1280px) {
      .blog .blog-item {
        flex: 0 0 23%;
        margin-bottom: 0;
        margin-right: 2%;
        max-width: 23%; }
        .blog .blog-item:nth-child(3n) {
          display: block;
          margin-right: 2%; }
        .blog .blog-item:nth-child(4n) {
          display: block;
          margin-right: 0; }
        .no-flexbox .blog .blog-item {
          display: inline;
          float: left;
          width: 23%; }
          .no-flexbox .blog .blog-item:nth-child(3n) {
            margin-right: 2%; }
          .no-flexbox .blog .blog-item:nth-child(4n) {
            display: inline;
            margin-right: 0; } }
    .blog .blog-item__image img {
      display: block;
      width: 100%; }
    .blog .blog-item__content {
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      padding: 20px;
      text-align: left; }
    .blog .blog-item__date {
      color: #999999;
      font-size: 80%;
      margin-top: auto; }
    .blog .blog-item .title {
      margin-bottom: 20px; }

.breadcrumb {
  font-size: 14px;
  font-size: 1.4rem;
  color: #000000;
  display: none;
  margin: 40px 0; }
  @media screen and (min-width: 600px) {
    .breadcrumb {
      display: block; } }
  .breadcrumb__item {
    color: #000000;
    display: inline-block;
    margin-right: 5px; }
    .breadcrumb__item::before {
      color: #00aacd;
      content: "";
      font-family: 'FontAwesome';
      margin-right: 5px; }
    .breadcrumb__item:first-child::before {
      display: none; }
  .breadcrumb a {
    color: #000000;
    text-decoration: none; }
    .breadcrumb a:hover {
      color: #2c3b47; }

.calendar,
.band--content .calendar {
  border: 1px solid #f7f7f7;
  display: flex;
  font-size: 85%;
  margin-bottom: 40px; }
  @media screen and (min-width: 768px) {
    .calendar,
    .band--content .calendar {
      font-size: 100%; } }
  .weeks__week--2 .calendar, .weeks__week--2
  .band--content .calendar {
    background-color: #e41b13; }
  .weeks__week--3 .calendar, .weeks__week--3
  .band--content .calendar {
    background-color: #fff2af; }
  .calendar__day,
  .band--content .calendar__day {
    border-left: 1px solid #f7f7f7;
    box-sizing: border-box;
    flex: 0 0 20%;
    max-width: 20%; }
    .calendar__day:nth-child(1),
    .band--content .calendar__day:nth-child(1) {
      border-left: 0; }
    .calendar__day--disabled .calendar__time,
    .calendar__day--disabled .calendar__time:hover,
    .band--content .calendar__day--disabled .calendar__time,
    .band--content .calendar__day--disabled .calendar__time:hover {
      background-color: #f7f7f7;
      color: #d4d4d4; }
  .calendar__time,
  .band--content .calendar__time {
    border-top: 1px solid #f7f7f7;
    cursor: pointer;
    min-height: 60px;
    padding: 5px;
    transition: all 200ms; }
    @media screen and (min-width: 768px) {
      .calendar__time,
      .band--content .calendar__time {
        padding: 30px 10px; } }
    .calendar__time:nth-child(1),
    .band--content .calendar__time:nth-child(1) {
      border-top: 0; }
    .calendar__time:hover,
    .band--content .calendar__time:hover {
      background-color: #00aacd; }
    .calendar__time--selected,
    .band--content .calendar__time--selected {
      background-color: #2c3b47 !important;
      /* stylelint-disable-line declaration-no-important */
      color: #ffffff !important;
      /* stylelint-disable-line declaration-no-important */
      font-weight: bold; }
  .calendar__content--small,
  .band--content .calendar__content--small {
    display: block; }
    @media screen and (min-width: 768px) {
      .calendar__content--small,
      .band--content .calendar__content--small {
        display: none; } }
  .calendar__content--large,
  .band--content .calendar__content--large {
    display: none; }
    @media screen and (min-width: 768px) {
      .calendar__content--large,
      .band--content .calendar__content--large {
        display: block; } }

.message,
.band--content .message {
  border: 1px solid #fff2af;
  margin-bottom: 20px;
  padding: 5px 10px;
  text-align: center; }
  .message.error,
  .band--content .message.error {
    background-color: #fef8f8;
    border-color: #e41b13; }
  .message.ok,
  .band--content .message.ok {
    border-color: #7ab317; }

.appointment,
.band--content .appointment {
  text-align: center; }
  .appointment__location,
  .band--content .appointment__location {
    margin-bottom: 20px;
    text-align: left; }
    .appointment__location .list,
    .band--content .appointment__location .list {
      list-style: none;
      margin-left: 0; }
      .appointment__location .list label,
      .band--content .appointment__location .list label {
        align-items: baseline;
        display: flex; }
      .appointment__location .list input,
      .band--content .appointment__location .list input {
        margin-right: 10px;
        position: relative;
        top: 2px; }
  .appointment__dropdown,
  .band--content .appointment__dropdown {
    min-width: 200px; }
    .appointment__dropdown .error,
    .band--content .appointment__dropdown .error {
      border: 1px solid #e41b13; }
  .appointment__fields,
  .band--content .appointment__fields {
    margin: 0 auto;
    max-width: 400px;
    text-align: left; }
    .appointment__fields .paragraph,
    .band--content .appointment__fields .paragraph {
      font-size: 90%;
      font-style: italic; }
  .appointment__field,
  .band--content .appointment__field {
    border: 1px solid #d4d4d4;
    box-sizing: border-box;
    font-family: "open-sans", sans-serif;
    font-size: inherit;
    margin-bottom: 10px;
    padding: 5px 10px;
    width: 100%; }
    .appointment__field::placeholder,
    .band--content .appointment__field::placeholder {
      color: #d4d4d4;
      font-style: italic; }
    .appointment__field[name='appointment__date'],
    .band--content .appointment__field[name='appointment__date'] {
      border-color: #f7f7f7;
      color: #d4d4d4; }
    .appointment__field.error,
    .band--content .appointment__field.error {
      border-color: #e41b13;
      color: #e41b13; }
  .appointment .button,
  .band--content .appointment .button {
    width: 100%; }

.weeks__navigation,
.band--content .weeks__navigation {
  color: #2c3b47;
  font-weight: bold;
  margin-bottom: 20px; }

.weeks__previous, .weeks__number, .weeks__next,
.band--content .weeks__previous,
.band--content .weeks__number,
.band--content .weeks__next {
  display: inline-block;
  margin: 0 5px; }
  .weeks__previous--disabled, .weeks__number--disabled, .weeks__next--disabled,
  .band--content .weeks__previous--disabled,
  .band--content .weeks__number--disabled,
  .band--content .weeks__next--disabled {
    color: #d4d4d4 !important;
    /* stylelint-disable-line declaration-no-important */ }

.weeks__previous, .weeks__next,
.band--content .weeks__previous,
.band--content .weeks__next {
  cursor: pointer; }
  .weeks__previous:hover, .weeks__next:hover,
  .band--content .weeks__previous:hover,
  .band--content .weeks__next:hover {
    color: #00aacd; }

.certificates {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: -20px; }
  .certificates__certificate {
    flex: 0 0 auto;
    margin: 0 5% 20px;
    max-width: 20%; }
    @media screen and (min-width: 768px) {
      .certificates__certificate {
        flex: 0 0 auto;
        margin: 0 2.5% 20px;
        max-width: 15%; } }
    .certificates__certificate img {
      max-height: 90px;
      max-width: 100%; }

.article--event__content {
  display: inline;
  float: left;
  width: 80%; }
  @media screen and (min-width: 600px) {
    .article--event__content {
      width: 85%; } }

.article--event__image-link, .article--event__placeholder {
  display: block;
  float: left;
  margin-right: 2%;
  max-width: auto;
  text-align: center;
  width: auto; }
  @media screen and (min-width: 600px) {
    .article--event__image-link, .article--event__placeholder {
      width: 13%; } }

.article--event__placeholder {
  background: #f7f7f7;
  padding: 3% 0; }
  .article--event__placeholder i {
    font-size: 28px;
    font-size: 2.8rem;
    color: #ffffff; }

.article--event__date--no-margin {
  margin-bottom: 0; }

[type='radio'] {
  vertical-align: text-bottom; }

[type='checkbox'] {
  vertical-align: bottom; }

::placeholder {
  color: #999999;
  font-style: italic; }

.form {
  clear: both;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative; }
  .form--error {
    border-color: #e41b13;
    padding-bottom: 40px;
    position: relative; }
  .form.form--search {
    display: flex;
    max-width: 800px; }
    .form.form--search input {
      border-bottom-right-radius: 0;
      border-right: 0;
      border-top-right-radius: 0;
      height: auto;
      max-width: none; }
    .form.form--search .link.button {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      margin-bottom: 0;
      margin-top: 0;
      width: auto; }
  .form__item {
    margin-bottom: 20px; }
    @media screen and (min-width: 768px) {
      .form__item {
        display: flex;
        margin-bottom: 10px; } }
    .form__item + .paragraph {
      font-size: 80%;
      text-align: right; }
    .form__item--date .form__input {
      display: flex; }
    .form__item--date .form__control--mini {
      flex: 1 1 30%; }
    .form__item--date .form__control--small {
      flex: 1 1 40%; }
    .form__item--date .form__control--date {
      background-color: #ffffff; }
    .no-touchevents .form__item--date .form__control--date {
      display: none; }
    .no-touchevents .form__item--date .form__control--date-helper {
      display: block; }
    .touchevents .form__item--date .form__control--date {
      display: block; }
    .touchevents .form__item--date .form__control--date-helper {
      display: none; }
    .form__item--actions {
      justify-content: flex-end; }
  .form__label {
    color: #181818;
    display: inline;
    flex: 1 1 100%;
    float: left;
    font-family: "open-sans", sans-serif;
    font-weight: bold;
    line-height: 1.75;
    margin-bottom: 3px;
    padding-top: 0;
    text-align: left; }
    @media screen and (min-width: 768px) {
      .form__label {
        flex: 0 1 35%;
        font-weight: inherit;
        margin-bottom: 0;
        padding-right: 20px;
        text-align: right; } }
  .form__required {
    font-size: 16px;
    font-size: 1.6rem;
    color: #e41b13;
    margin-left: 2px; }
  .form__optional {
    font-size: 16px;
    font-size: 1.6rem;
    color: #999999;
    font-style: italic;
    margin-left: 2px; }
  .form__input {
    clear: left;
    margin-left: 0; }
    @media screen and (min-width: 768px) {
      .form__input {
        flex: 1 1 65%; } }
    .form__input .form__control {
      margin-right: 4px; }
  .form__list {
    margin: 0 0 5px 5px;
    width: 100%; }
    .form__list-item {
      display: block;
      margin-bottom: 10px;
      padding: 0;
      position: relative;
      width: 100%; }
    .form__list .form__label {
      display: block;
      float: none;
      font-weight: inherit;
      line-height: 18px;
      margin-left: 25px;
      padding: 0;
      text-align: left;
      white-space: normal;
      width: auto; }
    .form__list:first-child {
      padding-top: 6px; }
    .form__list [type='radio'],
    .form__list [type='checkbox'] {
      float: left;
      left: 0;
      margin-bottom: 0;
      margin-left: -20px;
      position: absolute; }
  .form input,
  .form select,
  .form textarea {
    font-size: 16px;
    font-size: 1.6rem;
    border: 1px solid #d4d4d4;
    box-sizing: border-box;
    color: #181818;
    display: inline-block;
    font-family: "open-sans", sans-serif;
    font-weight: normal;
    height: 32px;
    line-height: normal;
    padding: 4px 10px;
    width: 100%; }
    .form input:invalid,
    .form select:invalid,
    .form textarea:invalid {
      border-color: #e41b13; }
  .form input,
  .form textarea {
    transition: all .2 linear; }
    .form input:focus,
    .form textarea:focus {
      border-color: #b3b3b3;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
      outline: 0; }
    .form input.form__control--mini,
    .form textarea.form__control--mini {
      width: 50px; }
    .form input.form__control--small,
    .form textarea.form__control--small {
      width: 80px; }
    .form input.form__control--placeholder,
    .form textarea.form__control--placeholder {
      color: #999999;
      font-weight: normal; }
  .form textarea {
    height: 120px;
    max-height: 400px;
    overflow-y: auto;
    resize: vertical; }
    @media screen and (min-width: 600px) {
      .content .form textarea {
        height: 200px;
        width: 100%; } }
  .form select {
    background: #ffffff;
    height: 32px;
    line-height: 32px;
    padding: initial;
    width: 100%; }
    @media screen and (min-width: 600px) {
      .content .form select {
        width: 70%; } }
    .form select.form__control--mini {
      width: 60px; }
    .form select.form__control--small {
      width: 90px; }
    .form select:focus {
      box-shadow: none;
      outline: 1px dotted #252525; }
  @media screen and (min-width: 600px) {
    .content .form input[type='text'],
    .content .form input[type='email'],
    .content .form input[type='password'] {
      width: 70%; }
      .content .form input[type='text'].form__control--mini,
      .content .form input[type='email'].form__control--mini,
      .content .form input[type='password'].form__control--mini {
        width: 50px; } }
  .form input[type='checkbox'], .form input[type='radio'] {
    border: 0;
    box-shadow: none;
    cursor: pointer;
    height: auto;
    line-height: normal;
    margin: 3px 0;
    padding: 0;
    width: auto; }
  .ie9 .form input[type='checkbox'] {
    position: relative;
    top: -1px; }
  .form input[type='file'] {
    border: initial;
    box-shadow: none;
    color: #181818;
    line-height: initial;
    padding: initial;
    padding-top: 7px; }
    .form input[type='file']:focus {
      box-shadow: none;
      outline: 1px dotted #252525; }
  .form .link.button {
    font-family: "prometo", sans-serif;
    font-weight: bold;
    line-height: 1.2;
    background-color: #00aacd;
    border: 0;
    border-radius: 4px;
    display: block;
    font-size: 110%;
    font-weight: 300;
    padding: 10px;
    width: 100%; }
    @media screen and (min-width: 768px) {
      .form .link.button {
        display: inline-block;
        margin-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        width: auto; } }
    .form .link.button:hover {
      background-color: #0088a4; }
  .form .form__item--error label,
  .form .form__item--error input,
  .form .form__item--error textarea {
    color: #e41b13; }
  .form .form__item--error input,
  .form .form__item--error textarea,
  .form .form__item--error select {
    border-color: #e41b13; }
    .form .form__item--error input:focus,
    .form .form__item--error textarea:focus,
    .form .form__item--error select:focus {
      box-shadow: 0 0 6px #f58682; }
  .body--checkout .form,
  .body--account .form {
    background: transparent;
    border: 0;
    padding: 0; }
    .body--checkout .form .form__column,
    .body--account .form .form__column {
      background-color: #f7f7f7;
      border: 1px solid #d4d4d4;
      box-sizing: border-box;
      margin: 0 0 20px; }
      @media screen and (min-width: 768px) {
        .body--checkout .form .form__column,
        .body--account .form .form__column {
          float: left;
          margin-right: 2%;
          min-height: 350px;
          width: 49%; } }
      @media screen and (min-width: 768px) {
        .body--checkout .form .form__column.form__column--full,
        .body--account .form .form__column.form__column--full {
          clear: both;
          float: none;
          margin-right: 0;
          min-height: 0;
          width: 100%; } }
      .body--checkout .form .form__column:nth-of-type(2n),
      .body--account .form .form__column:nth-of-type(2n) {
        margin-right: 0; }
      .body--checkout .form .form__column .title,
      .body--account .form .form__column .title {
        font-size: 20px;
        font-size: 2rem;
        background-color: #d4d4d4;
        padding: 10px 15px; }
        .body--checkout .form .form__column .title.title--sub,
        .body--account .form .form__column .title.title--sub {
          margin-top: 40px; }
      .body--checkout .form .form__column .form__content,
      .body--account .form .form__column .form__content {
        padding: 10px 20px; }
  .form.form--inline {
    background-color: none;
    border: 0;
    display: inline;
    margin: 0;
    padding: 0; }
  .form .form__sub {
    padding: 20px 0 20px 40px; }
  .form .form__list-item.form__list-item--collapsed .form__sub {
    display: none; }
  .form .form__input .form__list .form__sub .form__label {
    margin-left: 0; }
  .form .form__status {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: #ffffff;
    border-top: 1px solid #e41b13;
    bottom: 0;
    clear: both;
    color: #e41b13;
    display: none;
    font-style: italic;
    left: 0;
    line-height: 20px;
    padding: 5px 0;
    position: absolute;
    text-align: center;
    width: 100%; }
    .form .form__status.form__status--error {
      display: block; }
    .form .form__status.form__status--error-again {
      display: block; }

@media screen and (min-width: 768px) {
  .funds-buttons {
    display: flex; } }

.funds-buttons__button {
  background-color: #00aacd;
  border: 1px solid #00aacd;
  color: #ffffff;
  display: block;
  line-height: 1.2;
  margin-bottom: 20px;
  margin-right: 20px;
  min-width: 120px;
  padding: 10px 20px;
  text-decoration: none;
  transition: all 200ms ease; }
  .funds-buttons__button:hover {
    background-color: #ffffff;
    color: #00aacd; }
  .funds-buttons__button--active {
    background-color: #ffffff;
    color: #00aacd; }

.funds-buttons__title {
  font-family: "prometo", sans-serif;
  font-weight: bold; }

.funds-buttons__subtitle {
  font-family: "prometo", sans-serif;
  font-weight: 300; }

.funds-info {
  font-size: 16px;
  font-size: 1.6rem;
  background-color: #2c3b47;
  box-sizing: border-box;
  color: #ffffff !important;
  /* stylelint-disable-line declaration-no-important */
  margin-bottom: 20px;
  margin-left: auto;
  padding: 20px;
  position: relative;
  z-index: 2; }
  .sidebar__item .funds-info {
    padding: 0; }
  @media screen and (min-width: 768px) {
    .funds-info {
      margin-bottom: 0; } }
  @media screen and (min-width: 1280px) {
    .funds-info {
      max-width: 75%; }
      .sidebar__item .funds-info {
        max-width: 100%; } }
  @media screen and (min-width: 1280px) {
    .funds-info {
      z-index: 3; } }
  .funds-info .title {
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 20px; }
    .sidebar__item .funds-info .title {
      display: none; }
  .funds-info__table {
    font-size: 90%;
    margin-bottom: 0 !important;
    /* stylelint-disable-line declaration-no-important */
    min-width: 100%; }
    @media screen and (min-width: 1280px) {
      .funds-info__table {
        font-size: 100%; } }
    .funds-info__table thead {
      background-color: transparent !important;
      /* stylelint-disable-line declaration-no-important */
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
      .funds-info__table thead th {
        background-color: transparent !important;
        /* stylelint-disable-line declaration-no-important */ }
    .funds-info__table td,
    .funds-info__table th {
      background: transparent !important;
      /* stylelint-disable-line declaration-no-important */
      border: 0 !important;
      /* stylelint-disable-line declaration-no-important */
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      /* stylelint-disable-line declaration-no-important */
      color: #ffffff !important;
      /* stylelint-disable-line declaration-no-important */
      padding: 5px 10px 5px 0 !important;
      /* stylelint-disable-line declaration-no-important */
      white-space: nowrap; }
      .funds-info__table td:first-child,
      .funds-info__table th:first-child {
        max-width: 200px;
        white-space: normal; }
      .funds-info__table td:last-child,
      .funds-info__table th:last-child {
        padding-right: 0 !important;
        /* stylelint-disable-line declaration-no-important */ }
      .funds-info__table td.align--right,
      .funds-info__table th.align--right {
        text-align: right; }
      .funds-info__table td a,
      .funds-info__table th a {
        color: #ffffff; }
  .funds-info__date {
    font-size: 80%;
    text-align: right; }

.figure {
  clear: both;
  margin: 0 auto 20px;
  max-width: 100%; }
  .column .figure,
  .sidebar__item .figure {
    margin-left: 0;
    margin-right: 0; }
  @media screen and (min-width: 600px) {
    .figure--left, .figure--right {
      display: inline;
      max-width: 49% !important;
      /* stylelint-disable-line declaration-no-important */ }
    .figure--left {
      float: left;
      margin: 0 2% 20px 0; }
    .figure--right {
      float: right;
      margin: 0 0 20px 2%; }
    .figure--no-margin {
      margin: 0 0 20px; } }
  .figure--left {
    clear: left;
    float: left;
    margin-right: 2%; }
  .figure--right {
    clear: right;
    float: right;
    margin-left: 2%; }
  .figure--left + .figure--right {
    margin-left: 0;
    margin-right: 0; }
    .figure--left + .figure--right + * {
      clear: both; }
  .figure--right + .figure--left {
    clear: both; }
  .figure__image,
  .figure img {
    display: block;
    margin: 0 auto;
    max-width: 100%; }
    .column .figure__image, .column
    .figure img {
      margin-left: 0;
      margin-right: 0; }
  .figure figcaption, .figure__caption {
    font-family: "open-sans", sans-serif;
    font-weight: 300;
    line-height: 1.5;
    font-size: 11px;
    font-size: 1.1rem;
    color: #585858;
    display: block;
    margin: 6px auto 0;
    text-align: center; }
    @media screen and (min-width: 600px) {
      .figure figcaption, .figure__caption {
        font-size: 13px;
        font-size: 1.3rem; } }

.link.button,
.linkmodel5.button {
  font-size: 14px;
  font-size: 1.4rem;
  background-color: #00aacd;
  border: 0;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  margin-bottom: 20px;
  padding: 5px 10px;
  text-decoration: none;
  text-transform: none;
  transition: all .2s ease;
  white-space: nowrap; }
  .link.button:hover,
  .linkmodel5.button:hover {
    background: #0088a4;
    color: #ffffff; }
  .link.button .fa,
  .linkmodel5.button .fa {
    font-size: 95%;
    margin-right: 10px; }

.title__link {
  border-bottom: 1px solid #00aacd;
  color: #00aacd !important;
  /* stylelint-disable-line declaration-no-important */
  font-weight: 700; }
  .title__link:hover {
    border-bottom-color: #0088a4;
    color: #0088a4 !important;
    /* stylelint-disable-line declaration-no-important */ }

.link,
.linkmodel5 {
  color: #00aacd;
  transition: all 200ms ease; }
  .link:hover,
  .linkmodel5:hover {
    color: #0088a4; }
  .link--read-more,
  .linkmodel5--read-more {
    display: block;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase; }
    .link--read-more::after,
    .linkmodel5--read-more::after {
      content: "";
      font-family: 'FontAwesome';
      margin-left: 5px; }
  @media screen and (min-width: 600px) {
    .link.button,
    .linkmodel5.button {
      font-size: 20px;
      font-size: 2rem;
      padding: 10px 20px; } }
  .link.button::after,
  .linkmodel5.button::after {
    margin-left: 20px; }
  .link.button.button--right,
  .linkmodel5.button.button--right {
    float: right; }
  .link.button.button--hidden,
  .linkmodel5.button.button--hidden {
    display: none; }
  .link--recent-report,
  .linkmodel5--recent-report {
    display: block;
    font-weight: bold;
    margin-bottom: 40px;
    text-decoration: none; }
    .link--recent-report .fa,
    .linkmodel5--recent-report .fa {
      margin-right: 5px; }

.link--prices {
  color: #ffffff;
  font-size: 90%; }
  .link--prices:hover {
    color: #ffffff;
    text-decoration: none; }

.link--factsheet {
  background: #00aacd url("../images/logo-link.png") 20px 20px no-repeat;
  background-size: 28px 30px;
  color: #ffffff;
  display: block;
  margin-bottom: 0;
  margin-left: -20px;
  margin-top: -20px;
  padding: 20px;
  text-decoration: none;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .link--factsheet {
      background-size: 56px 61px;
      min-height: 60px; } }
  .link--factsheet strong,
  .link--factsheet span {
    display: block;
    padding-left: 40px; }
    @media screen and (min-width: 768px) {
      .link--factsheet strong,
      .link--factsheet span {
        padding-left: 80px; } }
  .link--factsheet strong {
    font-family: "prometo", sans-serif;
    font-weight: bold;
    line-height: 1.2;
    font-size: 20px;
    font-size: 2rem;
    font-weight: 700; }
    @media screen and (min-width: 768px) {
      .link--factsheet strong {
        font-size: 24px;
        font-size: 2.4rem;
        padding-top: 5px; } }
  .link--factsheet:hover {
    background-color: #00809a;
    color: #ffffff; }

.search-result__hit {
  background: #fff2af;
  padding: 3px;
  text-decoration: none; }

.toggle-menu {
  font-size: 20px;
  font-size: 2rem;
  font-family: "prometo", sans-serif;
  font-weight: bold;
  line-height: 1.2;
  background: #00aacd;
  color: #ffffff;
  height: 42px;
  line-height: 42px;
  padding: 0;
  position: absolute;
  right: 10px;
  text-align: center;
  text-decoration: none;
  top: 10px;
  width: 42px; }
  @media screen and (min-width: 600px) {
    .toggle-menu {
      display: none; } }
  .toggle-menu:hover {
    background: #2c3b47; }

.list {
  margin: 0 0 20px; }
  @media screen and (min-width: 768px) {
    .list {
      margin-bottom: 30px; } }
  @media screen and (min-width: 1024px) {
    .list {
      margin-bottom: 60px; } }
  .band--content .list {
    list-style: inherit;
    margin: 0 0 20px 20px; }
  .band--content .list--checks {
    list-style: none;
    margin-left: 0; }
  .list--checks .list__item {
    margin-bottom: 10px;
    padding-left: 1.5em;
    position: relative; }
    .list--checks .list__item::before {
      color: #00aacd;
      content: "";
      font-family: 'FontAwesome';
      left: 0;
      margin-right: 10px;
      position: absolute;
      top: 0; }
  .list__item .list {
    margin-bottom: 5px;
    margin-left: 20px; }
  .list--articles, .list--events {
    border-top: 1px solid #f7f7f7;
    line-height: 1.2;
    list-style: none;
    margin: 0 0 20px; }
    .list--articles__item, .list--events__item {
      border-bottom: 1px solid #f7f7f7; }
  .list__link {
    display: block;
    padding: 5px 0;
    text-decoration: none; }
    .list__link--title {
      color: #00aacd;
      display: block;
      margin-bottom: 5px; }
    .list__link:hover .list__link--title {
      color: #2c3b47; }
    .list__link--date, .list__link--location {
      font-size: 12px;
      font-size: 1.2rem;
      color: #999999;
      display: block; }
  .list--custom-icon,
  .band--content .list--custom-icon {
    list-style: none;
    margin-left: 0; }
    .list--custom-icon .list__item::before,
    .band--content .list--custom-icon .list__item::before {
      color: #00aacd;
      content: "";
      font-family: 'FontAwesome';
      margin-right: 10px; }
  .list .fa {
    margin-right: 5px; }

ol.list--checks li {
  list-style: decimal;
  margin-left: 1.5em !important;
  /* stylelint-disable-line declaration-no-important */
  padding-left: 10px !important;
  /* stylelint-disable-line declaration-no-important */ }
  ol.list--checks li::before {
    display: none !important;
    /* stylelint-disable-line declaration-no-important */ }

.band--footer .list__item i {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */ }

.paragraph {
  margin-bottom: 20px; }
  .paragraph--intro {
    font-size: 22px;
    font-size: 2.2rem; }
  .paragraph--right {
    text-align: right; }
  .paragraph--quote {
    font-size: 32px;
    font-size: 3.2rem;
    line-height: 1.2; }
    @media screen and (min-width: 768px) {
      .paragraph--quote {
        font-size: 40px;
        font-size: 4rem;
        float: left;
        margin-right: 10%;
        padding-bottom: 10px;
        padding-top: 10px;
        width: 40%; } }
    @media screen and (min-width: 1024px) {
      .paragraph--quote {
        font-size: 50px;
        font-size: 5rem;
        margin-right: 15%;
        width: 35%; } }
  .paragraph.paragraph--status {
    background-color: #fffcef;
    border: 2px solid #fff3b7;
    color: #bfb683;
    padding: 20px; }
    .paragraph.paragraph--status .link {
      color: #ccc28c; }
      .paragraph.paragraph--status .link:hover {
        color: #2c3b47; }
  .paragraph.status--success {
    background-color: #e4f0d1;
    border: 2px solid #5c8611;
    color: #496b0e;
    padding: 20px; }
    .paragraph.status--success .link {
      color: #95c245; }
      .paragraph.status--success .link:hover {
        color: #2c3b47; }
  .paragraph.status--error {
    background-color: #fad1d0;
    border: 2px solid #eb544e;
    color: #e41b13;
    padding: 20px; }
    .paragraph.status--error .link {
      color: #e94942; }
      .paragraph.status--error .link:hover {
        color: #2c3b47; }

sub {
  font-size: 80%;
  position: relative;
  top: 3px; }

sup {
  font-size: 80%;
  position: relative;
  top: -3px; }

.scroll-to-top {
  background-color: #00aacd;
  bottom: 20px;
  color: #ffffff;
  cursor: pointer;
  line-height: 1.4;
  opacity: 0;
  padding: 8px 13px;
  position: fixed;
  right: 20px;
  transition: all 400ms ease;
  z-index: -1; }
  @media screen and (min-width: 1024px) {
    .scroll-to-top {
      padding: 15px 20px; } }
  .scroll-to-top--active {
    display: block;
    opacity: .8;
    z-index: 10; }
  .scroll-to-top:hover {
    opacity: 1; }

.sidebar__item {
  font-size: 16px;
  font-size: 1.6rem;
  background: #f7f7f7;
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 20px 20px 0;
  position: relative;
  width: 100%; }
  .sidebar__item::before, .sidebar__item::after {
    content: '';
    display: table; }
  .sidebar__item::after {
    clear: both; }
  .sidebar__item--color1 {
    background: #00aacd;
    color: #ffffff; }
    .sidebar__item--color1 > *,
    .sidebar__item--color1 .title {
      color: #ffffff; }
  .sidebar__item--color2 {
    background: #2c3b47;
    color: #ffffff; }
    .sidebar__item--color2 > *,
    .sidebar__item--color2 .title {
      color: #ffffff; }
  .sidebar__item .call-to-action {
    background: #00aacd url("../images/logo-link.png") 20px 20px no-repeat;
    background-size: 28px 30px;
    padding: 20px 20px 20px 60px;
    box-sizing: border-box;
    color: #ffffff;
    display: block;
    margin-bottom: 0;
    margin-left: -20px;
    margin-top: -20px;
    text-decoration: none;
    width: calc(100% + 40px); }
    @media screen and (min-width: 768px) {
      .sidebar__item .call-to-action {
        background-size: 56px 61px;
        padding-left: 100px;
        min-height: 60px; } }
    .sidebar__item .call-to-action + .call-to-action {
      margin-top: 20px; }
    .sidebar__item .call-to-action .title {
      color: #ffffff;
      margin-bottom: 5px; }
    .sidebar__item .call-to-action > *:last-child {
      margin-bottom: 0; }
    .sidebar__item .call-to-action:hover {
      background-color: #00809a;
      color: #ffffff; }

.slides {
  overflow: hidden;
  position: relative; }
  .slides .slide {
    overflow: hidden;
    position: relative; }
    @media screen and (min-width: 768px) {
      .slides .slide {
        max-height: 90vh; } }
    .slides .slide__background {
      background-position: center;
      background-size: cover;
      box-sizing: border-box;
      min-height: 490px;
      position: relative;
      z-index: 0; }
      @media screen and (min-width: 600px) {
        .slides .slide__background {
          box-sizing: content-box;
          min-height: 200px;
          padding-bottom: 55%; } }
      @media screen and (min-width: 768px) {
        .slides .slide__background {
          padding-bottom: 50%; } }
      @media screen and (min-width: 1024px) {
        .slides .slide__background {
          min-height: 100px; } }
      @media screen and (min-width: 1400px) {
        .slides .slide__background {
          padding-bottom: 700px; } }
      .slides .slide__background::before {
        background-color: rgba(0, 0, 0, 0.3);
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1; }
    .slides .slide__text {
      font-size: 16px;
      font-size: 1.6rem;
      bottom: 0;
      color: #ffffff;
      left: 20px;
      max-width: 100%;
      position: absolute;
      right: 0;
      text-align: center;
      top: 160px;
      top: 160px; }
      @media screen and (min-width: 600px) {
        .slides .slide__text {
          left: 40px;
          right: auto;
          text-align: left;
          top: 180px; } }
      @media screen and (min-width: 768px) {
        .slides .slide__text {
          top: 190px; } }
      @media screen and (min-width: 1024px) {
        .slides .slide__text {
          font-size: 18px;
          font-size: 1.8rem;
          top: 250px; } }
    .slides .slide p {
      font-size: 20px;
      font-size: 2rem;
      display: block;
      font-weight: 300;
      line-height: 1.2;
      margin-bottom: 10px; }
      @media screen and (min-width: 600px) {
        .slides .slide p {
          font-size: 32px;
          font-size: 3.2rem;
          margin-bottom: 20px;
          max-width: 80vw; } }
      @media screen and (min-width: 1280px) {
        .slides .slide p {
          font-size: 42px;
          font-size: 4.2rem; } }
      .slides .slide p a {
        color: #ffffff;
        text-decoration: none; }
    .slides .slide .link--read-more {
      color: #00aacd; }
      .slides .slide .link--read-more:hover {
        color: #ffffff; }
  .slides .owl-dots {
    bottom: 0;
    display: none;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center; }
    @media screen and (min-width: 600px) {
      .slides .owl-dots {
        bottom: 30px;
        display: block; } }
    .slides .owl-dots .owl-dot {
      background-color: #d4d4d4;
      border-radius: 50%;
      display: inline-block;
      height: 15px;
      margin: 0 5px;
      width: 15px; }
      .slides .owl-dots .owl-dot.active {
        background-color: #00aacd; }
  .slides .owl-nav {
    bottom: 20px;
    display: flex;
    justify-content: center;
    left: 40px;
    position: absolute;
    right: 40px;
    z-index: 3; }
    @media screen and (min-width: 600px) {
      .slides .owl-nav {
        bottom: 0;
        right: auto; } }
    .slides .owl-nav .owl-prev,
    .slides .owl-nav .owl-next {
      font-size: 28px;
      font-size: 2.8rem;
      align-items: center;
      background-color: #ffffff;
      color: #2c3b47;
      display: flex;
      flex: 0 0 40px;
      height: 40px;
      justify-content: center;
      transition: all 200ms ease;
      width: 40px; }
      @media screen and (min-width: 600px) {
        .slides .owl-nav .owl-prev,
        .slides .owl-nav .owl-next {
          font-size: 40px;
          font-size: 4rem;
          flex: 0 0 60px;
          height: 60px;
          width: 60px; } }
      @media screen and (min-width: 1024px) {
        .slides .owl-nav .owl-prev,
        .slides .owl-nav .owl-next {
          font-size: 40px;
          font-size: 4rem;
          flex: 0 0 80px;
          height: 80px;
          width: 80px; } }
      .slides .owl-nav .owl-prev:hover,
      .slides .owl-nav .owl-next:hover {
        background-color: #2c3b47;
        color: #ffffff; }
    .slides .owl-nav .owl-prev::after {
      content: "";
      font-family: 'FontAwesome'; }
    .slides .owl-nav .owl-next::after {
      content: "";
      font-family: 'FontAwesome'; }

.table-wrapper {
  margin-bottom: 20px;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden; }
  @media screen and (min-width: 768px) {
    .table-wrapper {
      width: 55vw; } }
  @media screen and (min-width: 1280px) {
    .table-wrapper {
      overflow-x: visible;
      overflow-y: visible; } }
  .table-wrapper .table {
    margin-bottom: 0;
    max-width: 99%; }

.table,
.content table {
  font-size: 16px;
  font-size: 1.6rem;
  color: #252525;
  margin: 0 0 20px;
  text-align: left;
  width: 100%; }
  .table__head,
  .content table__head {
    font-weight: bold; }
  .table__cell,
  .table td,
  .table th,
  .content table__cell,
  .content table td,
  .content table th {
    background-color: #ffffff;
    border: 1px solid rgba(153, 153, 153, 0.4);
    font-size: 80%;
    line-height: 1.2;
    padding: 3px 5px;
    text-align: left; }
    @media screen and (min-width: 600px) {
      .table__cell,
      .table td,
      .table th,
      .content table__cell,
      .content table td,
      .content table th {
        font-size: 90%;
        padding: 5px; } }
    @media screen and (min-width: 768px) {
      .table__cell,
      .table td,
      .table th,
      .content table__cell,
      .content table td,
      .content table th {
        font-size: 100%;
        padding: 8px; } }
    .table__head .table__cell,
    .table--column .table__cell--head,
    .table--row .table__cell:first-child, .table__head
    .table td,
    .table--column
    .table td--head,
    .table--row
    .table td:first-child, .table__head
    .table th,
    .table--column
    .table th--head,
    .table--row
    .table th:first-child, .table__head
    .content table__cell,
    .table--column
    .content table__cell--head,
    .table--row
    .content table__cell:first-child, .table__head
    .content table td,
    .table--column
    .content table td--head,
    .table--row
    .content table td:first-child, .table__head
    .content table th,
    .table--column
    .content table th--head,
    .table--row
    .content table th:first-child {
      background-color: #f0f0f0;
      color: #000000;
      font-weight: bold; }
  .table thead th,
  .content table thead th {
    background-color: #f0f0f0;
    color: #000000;
    font-weight: bold; }
  .table__row:nth-child(even) .table__cell,
  .table tr:nth-child(even) td,
  .content table__row:nth-child(even) .table__cell,
  .content table tr:nth-child(even) td {
    background-color: #f0f0f0; }
  .band--content .table--downloads, .band--content
  .content table--downloads {
    border: 0; }
    .band--content .table--downloads td, .band--content
    .content table--downloads td {
      border: 0;
      font-size: 100%;
      line-height: 1.5;
      padding: 0 2% 0 0; }
      @media screen and (max-width: 599px) {
        .band--content .table--downloads td, .band--content
        .content table--downloads td {
          clear: both;
          display: block;
          float: left;
          width: 100%; } }
    .band--content .table--downloads .link, .band--content
    .content table--downloads .link {
      text-decoration: none; }
      .band--content .table--downloads .link .fa, .band--content
      .content table--downloads .link .fa {
        margin-right: 5px; }
  .sidebar .table__cell,
  .sidebar .table__row:nth-child(even) .table__cell,
  .sidebar .table--row .table__cell:first-child,
  .sidebar .table__head .table__cell, .sidebar
  .content table__cell,
  .sidebar
  .content table__row:nth-child(even) .table__cell,
  .sidebar
  .content table--row .table__cell:first-child,
  .sidebar
  .content table__head .table__cell {
    background-color: transparent !important;
    /* stylelint-disable-line declaration-no-important */
    border: 0;
    border-bottom: 1px solid #d4d4d4;
    color: #ffffff;
    font-weight: normal;
    padding-left: 0;
    padding-right: 0; }
  .sidebar .table__row:last-child .table__cell, .sidebar
  .content table__row:last-child .table__cell {
    border: 0;
    font-size: 80%; }
  .sidebar .table--row .table__cell:last-child, .sidebar
  .content table--row .table__cell:last-child {
    text-align: right; }
  .sidebar__item--color1 .table__cell,
  .sidebar__item--color1 .table__row:nth-child(even) .table__cell,
  .sidebar__item--color1 .table--row .table__cell:first-child,
  .sidebar__item--color1 .table__head .table__cell,
  .sidebar__item--color2 .table__cell,
  .sidebar__item--color2 .table__row:nth-child(even) .table__cell,
  .sidebar__item--color2 .table--row .table__cell:first-child,
  .sidebar__item--color2 .table__head .table__cell, .sidebar__item--color1
  .content table__cell,
  .sidebar__item--color1
  .content table__row:nth-child(even) .table__cell,
  .sidebar__item--color1
  .content table--row .table__cell:first-child,
  .sidebar__item--color1
  .content table__head .table__cell,
  .sidebar__item--color2
  .content table__cell,
  .sidebar__item--color2
  .content table__row:nth-child(even) .table__cell,
  .sidebar__item--color2
  .content table--row .table__cell:first-child,
  .sidebar__item--color2
  .content table__head .table__cell {
    border-color: rgba(255, 255, 255, 0.05); }

td .fa-arrow-up {
  color: #7ab317; }

td .fa-arrow-down {
  color: #e41b13; }

td.align--right {
  text-align: right !important;
  /* stylelint-disable-line declaration-no-important */ }

td small {
  font-size: 80%; }

.title {
  font-family: "prometo", sans-serif;
  font-weight: bold;
  line-height: 1.2;
  color: #181818;
  font-weight: 300;
  margin-bottom: .2em; }
  .title .link {
    color: #181818;
    text-decoration: none; }
  .title--h1 {
    font-size: 20px;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 20px; }
    @media screen and (min-width: 600px) {
      .title--h1 {
        font-size: 32px;
        font-size: 3.2rem; } }
    @media screen and (min-width: 768px) {
      .title--h1 {
        font-size: 36px;
        font-size: 3.6rem;
        margin-bottom: 40px; } }
    @media screen and (min-width: 1024px) {
      .title--h1 {
        font-size: 40px;
        font-size: 4rem; } }
  .title--header {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #ffffff;
    font-size: 200% !important;
    /* stylelint-disable-line declaration-no-important */
    font-weight: 700 !important;
    /* stylelint-disable-line declaration-no-important */
    left: -20px;
    max-width: 1680px !important;
    /* stylelint-disable-line declaration-no-important */
    position: relative;
    text-align: center;
    width: 100vw !important;
    /* stylelint-disable-line declaration-no-important */ }
    @media screen and (min-width: 600px) {
      .title--header {
        font-size: 400% !important;
        /* stylelint-disable-line declaration-no-important */
        left: -40px; } }
    @media screen and (min-width: 768px) {
      .title--header {
        font-size: 600% !important;
        /* stylelint-disable-line declaration-no-important */ } }
  .title--h2 {
    font-size: 24px;
    font-size: 2.4rem; }
    .band__content .title--h2 {
      font-size: 18px;
      font-size: 1.8rem; }
      @media screen and (min-width: 600px) {
        .band__content .title--h2 {
          font-size: 24px;
          font-size: 2.4rem; } }
  .title--h3 {
    font-size: 20px;
    font-size: 2rem; }
    .sidebar .title--h3 {
      font-weight: 700;
      margin-bottom: 20px; }
    .band--footer .title--h3 {
      font-weight: 500; }
  .title--h4, .title--h5, .title--h6 {
    font-size: 18px;
    font-size: 1.8rem; }
  .title--centered {
    text-align: center; }
  .title--band {
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 300;
    margin-bottom: 20px; }
    @media screen and (min-width: 600px) {
      .title--band {
        font-size: 28px;
        font-size: 2.8rem; } }
    @media screen and (min-width: 768px) {
      .title--band {
        font-size: 36px;
        font-size: 3.6rem;
        margin-bottom: 40px; } }
    @media screen and (min-width: 1024px) {
      .title--band {
        font-size: 40px;
        font-size: 4rem; } }

.search-result__subtitle {
  font-weight: 700 !important;
  /* stylelint-disable-line declaration-no-important */
  margin-top: 0 !important;
  /* stylelint-disable-line declaration-no-important */ }

.video-player,
.video {
  clear: both;
  display: block;
  margin: 20px auto 60px;
  max-width: 1080px;
  width: 100%; }
  .video-player__wrapper,
  .video__wrapper {
    height: 0;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 0 56.25%;
    position: relative;
    width: 100%; }
    .video-player__wrapper iframe,
    .video-player__wrapper object,
    .video-player__wrapper embed,
    .video__wrapper iframe,
    .video__wrapper object,
    .video__wrapper embed {
      height: 100% !important;
      /* stylelint-disable-line declaration-no-important */
      left: 0;
      position: absolute;
      top: 0;
      width: 100% !important;
      /* stylelint-disable-line declaration-no-important */ }

.paging {
  margin: 0 0 20px;
  text-align: right; }
  .paging::before, .paging::after {
    content: '';
    display: table; }
  .paging::after {
    clear: both; }
  .paging__item {
    display: inline-block;
    list-style: none; }
    .paging__item a,
    .paging__item span {
      display: block;
      font-weight: normal;
      margin: 0 2px 2px 0;
      padding: 0 3px 2px;
      text-decoration: none; }
    .paging__item a:hover {
      text-decoration: underline; }
    .paging__item span {
      color: #999999; }
    .paging__item--current a {
      background: #00aacd;
      color: #ffffff;
      cursor: default;
      padding: 0 8px 2px;
      text-decoration: none; }
    .paging__item--jump {
      color: #999999;
      font-weight: normal;
      padding: 0 3px; }
    .paging__item--previous, .paging__item--next {
      display: none; }
      @media screen and (min-width: 600px) {
        .paging__item--previous, .paging__item--next {
          display: inline-block; }
          .sidebar .paging__item--previous, .sidebar .paging__item--next {
            display: none; } }

.band--search {
  background: white;
  height: 50px;
  overflow: hidden; }
  .band--search form {
    display: block;
    height: 50px;
    margin: 0 auto;
    max-width: 600px;
    padding: 0; }
  .band--search .search {
    background: #ffffff;
    border: 1px solid #d4d4d4;
    display: block;
    height: 28px;
    margin: 10px auto;
    max-width: 800px;
    overflow: hidden;
    position: relative; }
    .band--search .search input {
      border: 0;
      display: inline;
      float: left;
      outline: none; }
      .band--search .search input.search__input {
        font-size: 14px;
        font-size: 1.4rem;
        background: #ffffff;
        border: 0;
        color: #252525;
        font-family: "open-sans", sans-serif;
        height: 24px;
        margin: 0;
        outline: none;
        padding: 2px 2px 2px 5px;
        width: 100%; }
        @media screen and (min-width: 600px) {
          .band--search .search input.search__input {
            font-size: 16px;
            font-size: 1.6rem; } }
        .lt-ie9 .band--search .search input.search__input {
          line-height: 24px; }
    .band--search .search .search__button {
      font-size: 14px;
      font-size: 1.4rem;
      background: #ffffff;
      border: 0;
      color: #999999;
      cursor: pointer;
      height: 26px;
      margin: 0;
      opacity: .5;
      outline: 0;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 28px; }
      .band--search .search .search__button:hover, .band--search .search .search__button:focus {
        color: #999999;
        opacity: 1; }
      .lt-ie9 .band--search .search .search__button {
        top: 2px; }

/**
 * Owl Carousel v2.2.0
 * Copyright 2013-2016 David Deutsch
 * Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d; }

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none; }

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 *  Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 *  Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img.owl-lazy {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

/*
 *  Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform 100ms ease;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3, 1.3);
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/**
 * Owl Carousel v2.2.0
 * Copyright 2013-2016 David Deutsch
 * Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
 */
/*
 *  Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-nav [class*='owl-'] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px; }

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none; }

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline; }

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px; }

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791; }
