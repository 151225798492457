.site {
  background: $color-white;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: $bp-max-site-width + px;
  min-height: 100vh;
  min-width: 300px;
  overflow: hidden;
  position: relative;

  @include mq($bp-max-site-width + 2) {
    border-left: 1px solid shade($color-body, 10);
    border-right: 1px solid shade($color-body, 10);
  }
}

.hide-on-mobile {
  @include mq($bp-small, max) {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

// .hide-on-tablet {
//   @include mq($bp-small) {
//     @include mq($bp-xlarge, max) {
//       display: none !important; /* stylelint-disable-line declaration-no-important */
//     }
//   }
// }

.hide-on-desktop {
  @include mq($bp-small) {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }
}
