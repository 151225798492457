.menu.menu--legal {
  display: block;

  @include mq($bp-small) {
    display: inline-block;
  }

  .menu {

    &__item {
      display: inline-block;
      margin-left: $gutter / 2;

      &::before {
        margin-right: $gutter / 2;
      }

      &:first-child::before {
        @include mq($bp-small, max) {
          content: '';
        }
      }
    }

    &__link {
      color: $color-white;
      text-decoration: none;
      transition: all 200ms ease;

      &:hover {
        color: $color-primary;
      }
    }
  }
}
