//  PARAGRAAF

.paragraph {
  margin-bottom: $gutter;

  &--intro {
    @include font-size(22);
  }

  &--right {
    text-align: right;
  }

  &--quote {
    @include font-size(32);

    line-height: 1.2;

    @include mq($bp-medium) {
      @include font-size(40);

      float: left;
      margin-right: 10%;
      padding-bottom: $gutter / 2;
      padding-top: $gutter / 2;
      width: 40%;
    }

    @include mq($bp-large) {
      @include font-size(50);

      margin-right: 15%;
      width: 35%;
    }
  }

  &.paragraph--status {
    background-color: tint($color-status-info, 80%);
    border: 2px solid tint($color-status-info, 10%);
    color: shade($color-status-info, 25%);
    padding: 20px;

    .link {
      color: shade($color-status-info, 20%);

      &:hover {
        color: $color-secondary;
      }
    }
  }

  &.status--success {
    background-color: tint($color-status-ok, 80%);
    border: 2px solid shade($color-status-ok, 25%);
    color: shade($color-status-ok, 40%);
    padding: 20px;

    .link {
      color: tint($color-status-ok, 20%);

      &:hover {
        color: $color-secondary;
      }
    }
  }

  &.status--error {
    background-color: tint($color-status-error, 80%);
    border: 2px solid tint($color-status-error, 25%);
    color: $color-status-error;
    padding: 20px;

    .link {
      color: tint($color-status-error, 20%);

      &:hover {
        color: $color-secondary;
      }
    }
  }
}

sub {
  font-size: 80%;
  position: relative;
  top: 3px;
}

sup {
  font-size: 80%;
  position: relative;
  top: -3px;
}
