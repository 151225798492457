.band.band--rating {
  @include font-size(22);

  text-align: center;

  @include mq($bp-medium) {
    @include font-size(27);
  }

  .band__content {
    padding-bottom: $gutter * 2;
    padding-top: $gutter * 2;

    @include mq($bp-small) {
      padding-bottom: $gutter * 4;
      padding-top: $gutter * 4;
    }
  }

  .paragraph {
    margin-left: auto;
    margin-right: auto;
    max-width: 475px;

    strong {
      color: $color-primary;
      font-size: 120%;
      font-weight: bold;
    }

    ~ .paragraph {
      @include font-size(20);

      max-width: 650px;

      @include mq($bp-medium) {
        @include font-size(24);
      }
    }
  }

  .title {
    @include font-size(26);

    text-transform: uppercase;

    @include mq($bp-medium) {
      @include font-size(30);
    }
  }

  .rating {
    @include font-size(32);

    color: $color-primary;
    margin-bottom: $gutter * 2;

    @include mq($bp-medium) {
      @include font-size(41);
    }
  }
}
