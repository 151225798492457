.social {
  @include mq($bp-small) {
    @include mq($bp-large, max) {
      flex: 1 1 33%;
      max-width: 33%;

      > * {
        margin-left: $gutter !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }

  &__list {
    margin: 0;

    li {
      display: inline-block;
      font-size: 0;
      margin-right: $gutter / 4;

      a {
        @include font-size($footer-item-size);

        color: $color-primary;
        display: block;
        line-height: $footer-item-size + px;
        text-decoration: none;
      }
    }
  }
}
