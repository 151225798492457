[type='radio'] {
  vertical-align: text-bottom;
}

[type='checkbox'] {
  vertical-align: bottom;
}

::placeholder {
  color: $color-grey;
  font-style: italic;
}

.form {
  clear: both;
  margin-bottom: $gutter;
  overflow: hidden;
  position: relative;

  &--error {
    border-color: $color-status-error;
    padding-bottom: 40px;
    position: relative;
  }

  &.form--search {
    display: flex;
    max-width: 800px;

    input {
      border-bottom-right-radius: 0;
      border-right: 0;
      border-top-right-radius: 0;
      height: auto;
      max-width: none;
    }

    .link.button {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      margin-bottom: 0;
      margin-top: 0;
      width: auto;
    }
  }

  &__item {
    margin-bottom: $gutter;

    @include mq($bp-medium) {
      display: flex;
      margin-bottom: $gutter / 2;
    }

    + .paragraph {
      font-size: 80%;
      text-align: right;
    }

    &--date {

      .form__input {
        display: flex;
      }

      .form__control--mini {
        flex: 1 1 30%;
      }

      .form__control--small {
        flex: 1 1 40%;
      }

      .form__control--date {
        background-color: $color-white;
      }

      .no-touchevents & {

        .form__control--date {
          display: none;
        }

        .form__control--date-helper {
          display: block;
        }
      }

      .touchevents & {

        .form__control--date {
          display: block;
        }

        .form__control--date-helper {
          display: none;
        }
      }
    }

    &--actions {
      justify-content: flex-end;
    }
  }

  &__label {
    color: $color-text;
    display: inline;
    flex: 1 1 100%;
    float: left;
    font-family: $font-default;
    font-weight: bold;
    line-height: 1.75;
    margin-bottom: 3px;
    padding-top: 0;
    text-align: left;

    @include mq($bp-medium) {
      flex: 0 1 35%;
      font-weight: inherit;
      margin-bottom: 0;
      padding-right: 20px;
      text-align: right;
    }
  }

  &__required {
    @include font-size(16);

    color: $color-status-error;
    margin-left: 2px;
  }

  &__optional {
    @include font-size(16);

    color: $color-grey;
    font-style: italic;
    margin-left: 2px;
  }

  &__input {
    clear: left;
    margin-left: 0;

    @include mq($bp-medium) {
      flex: 1 1 65%;
    }

    .form__control {
      margin-right: 4px;
    }
  }

  &__list {
    margin: 0 0 5px 5px;
    width: 100%;

    &-item {
      display: block;
      margin-bottom: 10px;
      padding: 0;
      position: relative;
      width: 100%;
    }

    .form__label {
      display: block;
      float: none;
      font-weight: inherit;
      line-height: 18px;
      margin-left: 25px;
      padding: 0;
      text-align: left;
      white-space: normal;
      width: auto;
    }

    &:first-child {
      padding-top: 6px;
    }

    [type='radio'],
    [type='checkbox'] {
      float: left;
      left: 0;
      margin-bottom: 0;
      margin-left: -20px;
      position: absolute;
    }
  }

  input,
  select,
  textarea {
    @include font-size();

    border: 1px solid $color-grey-light;
    box-sizing: border-box;
    color: $color-text;
    display: inline-block;
    font-family: $font-default;
    font-weight: normal;
    height: 32px;
    line-height: normal;
    padding: 4px 10px;
    width: 100%;

    &:invalid {
      border-color: $color-status-error;
    }
  }

  input,
  textarea {
    transition: all .2 linear;

    &:focus {
      border-color: lighten($color-grey, 10%);
      box-shadow: 0 0 8px rgba($color-black, .2);
      outline: 0;
    }

    &.form__control--mini {
      width: 50px;
    }

    &.form__control--small {
      width: 80px;
    }

    &.form__control--placeholder {
      color: $color-grey;
      font-weight: normal;
    }
  }

  textarea {
    height: 120px;
    max-height: 400px;
    overflow-y: auto;
    resize: vertical;

    @include mq($bp-small) {

      .content & {
        height: 200px;
        width: 100%;
      }
    }
  }

  select {
    background: $color-white;
    height: 32px;
    line-height: 32px;
    padding: initial;
    width: 100%;

    @include mq($bp-small) {

      .content & {
        width: 70%;
      }
    }

    &.form__control--mini { width: 60px; }

    &.form__control--small { width: 90px; }

    &:focus {
      box-shadow: none;
      outline: 1px dotted $color-grey-dark;
    }
  }

  input {
    @include mq($bp-small) {

      .content &[type='text'],
      .content &[type='email'],
      .content &[type='password'] {
        width: 70%;

        &.form__control--mini {
          width: 50px;
        }
      }
    }

    &[type='checkbox'],
    &[type='radio'] {
      border: 0;
      box-shadow: none;
      cursor: pointer;
      height: auto;
      line-height: normal;
      margin: 3px 0;
      padding: 0;
      width: auto;
    }

    .ie9 &[type='checkbox'] {
      position: relative;
      top: -1px;
    }

    &[type='file'] {
      border: initial;
      box-shadow: none;
      color: $color-text;
      line-height: initial;
      padding: initial;
      padding-top: 7px;

      &:focus {
        box-shadow: none;
        outline: 1px dotted $color-grey-dark;
      }
    }
  }

  .link.button {
    @include title-font();

    background-color: $color-primary;
    border: 0;
    border-radius: 4px;
    display: block;
    font-size: 110%;
    font-weight: 300;
    padding: 10px;
    width: 100%;

    @include mq($bp-medium) {
      display: inline-block;
      margin-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      width: auto;
    }

    &:hover {
      background-color: $color-hover;
    }
  }

  //ERROR

  .form__item--error {

    label,
    input,
    textarea {
      color: $color-status-error;
    }

    input,
    textarea,
    select {
      border-color: $color-status-error;

      &:focus {
        box-shadow: 0 0 6px lighten($color-status-error, 25%);
      }
    }
  }

  .body--checkout &,
  .body--account & {
    background: transparent;
    border: 0;
    padding: 0;

    .form__column {
      background-color: $color-grey-lighter;
      border: 1px solid $color-grey-light;
      box-sizing: border-box;
      margin: 0 0 20px;

      @include mq($bp-medium) {
        float: left;
        margin-right: 2%;
        min-height: 350px;
        width: 49%;
      }

      &.form__column--full {
        @include mq($bp-medium) {
          clear: both;
          float: none;
          margin-right: 0;
          min-height: 0;
          width: 100%;
        }
      }

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      .title {
        @include font-size(20);

        background-color: $color-grey-light;
        padding: 10px 15px;

        &.title--sub {
          margin-top: 40px;
        }
      }

      .form__content {
        padding: 10px 20px;
      }
    }
  }

  &.form--inline {
    background-color: none;
    border: 0;
    display: inline;
    margin: 0;
    padding: 0;
  }

  .form__sub {
    padding: 20px 0 20px 40px;
  }

  .form__list-item.form__list-item--collapsed {

    .form__sub {
      display: none;
    }
  }

  .form__input .form__list .form__sub .form__label {
    margin-left: 0;
  }

  .form__status {
    // @extend .animated !optional;
    //background: $color-red;
    @include text-overflow();

    background-color: $color-white;
    border-top: 1px solid $color-status-error;
    bottom: 0;
    clear: both;
    color: $color-status-error;
    display: none;
    font-style: italic;
    left: 0;
    line-height: 20px;
    padding: 5px 0;
    position: absolute;
    text-align: center;
    width: 100%;

    &.form__status--error {
      // @extend .fadeInRight !optional;
      // @include animation-duration(200ms); FIX GULP?
      display: block;
    }

    &.form__status--error-again {
      // @extend .shake !optional;
      display: block;
    }
  }
}
