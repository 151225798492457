.figure {
  clear: both;
  margin: 0 auto $gutter;
  max-width: 100%;

  .column &,
  .sidebar__item & {
    margin-left: 0;
    margin-right: 0;
  }

  //  UITLIJNING
  @include mq($bp-small) {

    &--left,
    &--right {
      display: inline;
      max-width: 49% !important; /* stylelint-disable-line declaration-no-important */
    }

    &--left {
      float: left;
      margin: 0 2% $gutter 0;
    }

    &--right {
      float: right;
      margin: 0 0 $gutter 2%;
    }

    &--no-margin {
      margin: 0 0 $gutter;
    }
  }

  &--left {
    clear: left;
    float: left;
    margin-right: 2%;
  }

  &--right {
    clear: right;
    float: right;
    margin-left: 2%;
  }

  &--left + &--right {
    margin-left: 0;
    margin-right: 0;

    + * {
      clear: both;
    }
  }

  &--right + &--left {
    clear: both;
  }

  // &--left + *:not(.title),
  // &--left + .title + * {
  //   @include clearfix();
  // }

  // &--right + *:not(.title),
  // &--right + .title + * {
  //   @include clearfix();
  // }

  // IMG

  &__image,
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;

    .column & {
      margin-left: 0;
      margin-right: 0;
    }
  }

  //  ONDERSCHRIFT

  figcaption,
  &__caption {
    @include base-font();
    @include font-size(11);

    color: lighten($color-text, 25);
    display: block;
    margin: 6px auto 0;
    text-align: center;

    @include mq($bp-small) {
      @include font-size(13);
    }
  }
}
