.breadcrumb {
  @include font-size(14);

  color: $color-black;
  display: none;
  margin: ($gutter * 2) 0;

  @include mq($bp-small) {
    display: block;
  }

  &__item {
    color: $color-black;
    display: inline-block;
    margin-right: 5px;

    &::before {
      color: $color-primary;
      content: $fa-var-angle-right;
      font-family: 'FontAwesome';
      margin-right: 5px;
    }

    &:first-child {

      &::before {
        display: none;
      }
    }
  }

  a {
    color: $color-black;
    text-decoration: none;

    &:hover {
      color: $color-secondary;
    }
  }
}
