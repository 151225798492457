.table-wrapper {
  margin-bottom: $gutter;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;

  @include mq($bp-medium) {
    width: 55vw;
  }

  @include mq($bp-xlarge) {
    overflow-x: visible;
    overflow-y: visible;
  }

  .table {
    margin-bottom: 0;
    max-width: 99%;
  }
}

.table,
.content table {
  @include font-size(16);

  color: $color-grey-dark;
  margin: 0 0 $gutter;
  text-align: left;
  width: 100%;

  &__head {
    font-weight: bold;
  }

  &__cell,
  td,
  th {
    background-color: $color-white;
    border: 1px solid rgba($color-grey, .4);
    font-size: 80%;
    line-height: $font-lineheight-alt;
    padding: 3px 5px;
    text-align: left;

    @include mq($bp-small) {
      font-size: 90%;
      padding: 5px;
    }

    @include mq($bp-medium) {
      font-size: 100%;
      padding: 8px;
    }

    .table__head &,
    .table--column &--head,
    .table--row &:first-child {
      // background-color: $color-primary !important; /* stylelint-disable-line declaration-no-important */
      // border-color: shade($color-primary, 20);
      // color: $color-white;
      background-color: shade($color-grey-lighter, 3);
      color: $color-black;
      font-weight: bold;
    }
  }

  thead th {
    // background-color: $color-primary !important; /* stylelint-disable-line declaration-no-important */
    // border-color: shade($color-primary, 20);
    background-color: shade($color-grey-lighter, 3);
    color: $color-black;
    font-weight: bold;
  }

  &__row:nth-child(even) .table__cell,
  tr:nth-child(even) td {
    background-color: shade($color-grey-lighter, 3);
  }

  .band--content &--downloads {
    border: 0;

    td {
      border: 0;
      font-size: 100%;
      line-height: 1.5;
      padding: 0 2% 0 0;

      @include mq($bp-small, max) {
        clear: both;
        display: block;
        float: left;
        width: 100%;
      }
    }

    .link {
      text-decoration: none;

      .fa {
        margin-right: 5px;
      }
    }
  }

  .sidebar & {

    &__cell,
    &__row:nth-child(even) .table__cell,
    &--row .table__cell:first-child,
    &__head .table__cell {
      background-color: transparent !important; /* stylelint-disable-line declaration-no-important */
      border: 0;
      border-bottom: 1px solid $color-grey-light;
      color: $color-white;
      font-weight: normal;
      padding-left: 0;
      padding-right: 0;
    }

    &__row:last-child .table__cell {
      border: 0;
      font-size: 80%;
    }

    &--row .table__cell:last-child {
      text-align: right;
    }
  }

  .sidebar__item--color1 &,
  .sidebar__item--color2 & {

    &__cell,
    &__row:nth-child(even) .table__cell,
    &--row .table__cell:first-child,
    &__head .table__cell {
      border-color: rgba($color-white, .05);
    }
  }
}

td {

  .fa-arrow-up {
    color: $color-status-ok;
  }

  .fa-arrow-down {
    color: $color-status-error;
  }

  &.align--right {
    text-align: right !important; /* stylelint-disable-line declaration-no-important */
  }

  small {
    font-size: 80%;
  }
}
