.logo-holder {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 0;
  justify-content: center;
  text-align: center;

  .logo-img {
    display: inline-block;
    margin: 0 ($gutter / 2) $gutter;
    max-height: 100px;
    max-width: 40vw;

    a {
      display: block;
    }

    img {
      display: block;
      max-height: 100px;
      max-width: 100%;
      // width: auto !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}
