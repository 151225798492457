.funds-info {
  @include font-size(16);

  background-color: $color-secondary;
  box-sizing: border-box;
  color: $color-white !important; /* stylelint-disable-line declaration-no-important */
  margin-bottom: $gutter;
  margin-left: auto;
  padding: $gutter;
  position: relative;
  z-index: 2;

  .sidebar__item & {
    padding: 0;
  }

  @include mq($bp-medium) {
    margin-bottom: 0;
  }

  @include mq($bp-xlarge) {
    max-width: 75%;

    .sidebar__item & {
      max-width: 100%;
    }
  }

  @include mq($bp-xlarge) {
    z-index: 3;
  }

  .title {
    color: $color-white;
    font-weight: bold;
    margin-bottom: $gutter;

    .sidebar__item & {
      display: none;
    }
  }

  &__table {
    // margin-bottom: $gutter;
    font-size: 90%;
    margin-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
    min-width: 100%;

    @include mq($bp-xlarge) {
      font-size: 100%;
    }

    thead {
      background-color: transparent !important; /* stylelint-disable-line declaration-no-important */
      border-bottom: 1px solid transparentize($color-white, .5);

      th {
        background-color: transparent !important; /* stylelint-disable-line declaration-no-important */
      }
    }

    td,
    th {
      background: transparent !important; /* stylelint-disable-line declaration-no-important */
      border: 0 !important; /* stylelint-disable-line declaration-no-important */
      border-bottom: 1px solid rgba($color-white, .05); /* stylelint-disable-line declaration-no-important */
      color: $color-white !important; /* stylelint-disable-line declaration-no-important */
      padding: 5px 10px 5px 0 !important; /* stylelint-disable-line declaration-no-important */
      white-space: nowrap;

      // @include mq($bp-xlarge) {
      //   padding: 5px 40px 5px 0 !important; /* stylelint-disable-line declaration-no-important */
      // }

      &:first-child {
        max-width: 200px;
        white-space: normal;
      }

      &:last-child {
        padding-right: 0 !important; /* stylelint-disable-line declaration-no-important */
      }

      &.align--right {
        text-align: right;
      }

      a {
        color: $color-white;

        @if $website == intereffekt {
          @include mq($bp-medium) {
            white-space: nowrap;
          }
        }
      }
    }
  }

  &__date {
    font-size: 80%;
    text-align: right;
  }
}
