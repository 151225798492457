.band.band--read-more {
  border-top: 1px solid rgba($color-secondary, .25);
  padding-top: $gutter * 2;

  .link:not(.button) {
    border-bottom: 1px dashed $color-secondary;
    text-decoration: none;
  }

  .title {
    color: $color-black;
  }

  .button {
    margin-right: $gutter;

    .fa {
      margin-right: $gutter;
    }
  }
}
