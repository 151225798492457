//  STANDAARD

.article {
  @include clearfix();

  border-bottom: 1px solid $color-grey-lighter;
  margin-bottom: $gutter / 2;
  padding-bottom: $gutter / 2;

  @include mq($bp-small) {
    margin-bottom: $gutter;
    padding-bottom: 20px;
  }

  .lt-ie9 & {

    &::before,
    &::after {
      margin: 0 !important; /* stylelint-disable-line declaration-no-important */
      padding: 0 !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &--first {
    border-top: 1px solid $color-grey-lighter;
    padding-top: $gutter / 2;

    @include mq($bp-small) {
      padding-top: 20px;
    }
  }

  &--last {
    margin-bottom: 0;
  }

  // ARTIKEL MET AFBEELDING

  &__content {

    &--image {
      @include mq($bp-xsmall) {
        display: inline;
        float: left;
        width: 70%;
      }
    }
  }

  &__image {
    display: block;
    margin: 0 auto;
    max-width: 100%;

    // @include mq($bp-medium) {

    //   &--link {
    //     min-width: 200px;
    //   }
    // }

    @include mq($bp-xsmall) {
      width: 100%;
    }

    &-link {
      display: block;
      margin-bottom: $gutter / 2;
      text-align: center;
      text-decoration: none;

      @include mq($bp-xsmall) {
        display: inline;
        float: left;
        margin-right: 2%;
        // max-width: auto;
        width: 28%;
        //min-width: 200px;
      }
    }
  }

  &__date,
  &__location {
    color: $color-grey;
    display: block;
    margin-bottom: $gutter / 2;
  }
}

//
//  VOOR OPSOMMING IN SIDEBARS ZIE LIST.SCSS
//

//  DETAIL

.detail-info {
  // @include font-size(12);
  color: $color-grey;
  line-height: $font-lineheight;
  margin-bottom: $gutter / 2;
  position: relative;
  top: - $gutter / 2;

  span {
    display: block;

    span {
      display: none;

      @include mq($bp-small) {
        display: inline;
      }
    }
  }
}

.blog-detail {

  .figure--left {
    float: none;
    margin: 0 auto $gutter;

    @include mq($bp-small) {
      display: block;
      margin-bottom: $gutter !important; /* stylelint-disable-line declaration-no-important */
      max-width: 100% !important; /* stylelint-disable-line declaration-no-important */

      @include mq($bp-medium, max) {
        display: inline;
        float: left;
        margin-right: $gutter;
        max-width: 40% !important; /* stylelint-disable-line declaration-no-important */
      }
    }

    @include mq($bp-large) {
      display: inline;
      float: left;
      margin-right: $gutter;
      max-width: 40% !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.blog {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto $gutter;
  max-width: 300px;

  .no-flexbox & {
    @include clearfix();

    display: block;
  }

  @include mq($bp-small) {
    align-items: stretch;
    max-width: 700px;
  }

  @include mq($bp-large) {
    max-width: 1400px;
  }

  .blog-item {
    @include font-size(16);

    background-color: $color-white;
    box-shadow: 0 0 5px 0 rgba($color-black, .1);
    color: inherit;
    margin-bottom: $gutter;
    position: relative;
    text-decoration: none;
    top: 0;
    transition: top 300ms ease, box-shadow 300ms ease;

    &:hover {
      box-shadow: 0 0 30px 0 rgba($color-black, .35);
    }

    &:nth-child(3n),
    &:nth-child(4n) {
      display: none;
    }

    @include mq($bp-small) {
      @include mq($bp-large, max) {
        flex: 0 0 47.5%;
        margin-right: 5%;
        max-width: 47.5%;

        &:nth-child(2n) {
          margin-right: 0;
        }

        &:hover {
          top: - ($gutter / 2); // -$gutter * 2;
        }

        .no-flexbox & {
          display: inline;
          float: left;
          width: 47.5%;

          &:nth-child(3n),
          &:nth-child(4n) {
            display: none;
          }
        }
      }
    }

    @include mq($bp-large) {
      flex: 0 0 30%;
      margin-bottom: 0;
      margin-right: 5%;
      max-width: 30%;

      &:nth-child(3n) {
        display: block;
        margin-right: 0;
      }

      .no-flexbox & {
        display: inline;
        float: left;
        width: 30%;

        &:nth-child(4n) {
          display: none;
        }
      }
    }

    @include mq($bp-xlarge) {
      flex: 0 0 23%;
      margin-bottom: 0;
      margin-right: 2%;
      max-width: 23%;

      &:nth-child(3n) {
        display: block;
        margin-right: 2%;
      }

      &:nth-child(4n) {
        display: block;
        margin-right: 0;
      }

      .no-flexbox & {
        display: inline;
        float: left;
        width: 23%;

        &:nth-child(3n) {
          margin-right: 2%;
        }

        &:nth-child(4n) {
          display: inline;
          margin-right: 0;
        }
      }
    }

    &__image {

      img {
        display: block;
        width: 100%;
      }
    }

    &__content {
      background-color: $color-white;
      display: flex;
      flex-direction: column;
      padding: $gutter;
      text-align: left;
    }

    &__date {
      color: $color-grey;
      font-size: 80%;
      margin-top: auto;
    }

    .title {
      margin-bottom: $gutter;
    }
  }
}
