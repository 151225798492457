.band.band--team-background {
  @include font-size(16);

  color: $color-white;
  text-align: center;

  @include mq($bp-medium) {
    @include font-size(21);

    min-height: 300px;
  }

  .band__content {
    max-width: 650px;

    @include mq($bp-medium) {
      padding-bottom: $gutter * 2;
      padding-top: $gutter * 2;
    }
  }

  .title--h2 {
    @include font-size(28);

    color: $color-white;
    text-transform: uppercase;

    @include mq($bp-small) {
      @include font-size(34);
    }

    @include mq($bp-medium) {
      @include font-size(40);
    }

    @include mq($bp-large) {
      @include font-size(48);
    }
  }

  .link {
    color: $color-white;

    &:hover {
      color: $color-primary;
    }

    &--read-more {

      &::after {
        color: $color-primary;
      }
    }
  }
}
