.band--footer {
  @include font-size(14);

  background: $color-black;
  color: $color-white;
  font-weight: normal;

  .title {
    color: $color-white;
    margin-bottom: $gutter;
  }

  a,
  .link {
    color: $color-white;
    text-decoration: none;

    &:hover {
      color: $color-primary;
    }
  }

  .fa {
    font-size: 150%;
  }

  .figure {
    margin-left: 0;
    width: 300px;

    img {
      margin-left: 0;
      width: 100%;
    }
  }

  .columns {

    &--4 {

      .column {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;

        @include mq($bp-small, max) {
          margin-bottom: 0;

          .title--spacer {
            display: none;
          }
        }
      }

      @include mq($bp-small) {
        @include mq($bp-large, max) {

          .column {
            margin-right: 5%;
            max-width: 28.33%;
            width: 28.33%;

            &:nth-child(3n) {
              margin-right: 0;
            }
          }
        }
      }

      @include mq($bp-large) {

        .column {
          margin-right: 3.33%;
          max-width: 22.5%;
          width: 22.5%;

          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@import 'copyright',
  'social';
