.paging {
  @include clearfix();

  margin: 0 0 $gutter;
  text-align: right;

  &__item {
    display: inline-block;
    list-style: none;

    a,
    span {
      display: block;
      font-weight: normal;
      margin: 0 2px 2px 0;
      padding: 0 3px 2px;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

    span {
      color: $color-grey;
    }

    &--current {

      a {
        background: $color-primary;
        color: $color-white;
        cursor: default;
        padding: 0 8px 2px;
        text-decoration: none;
      }
    }

    &--jump {
      color: $color-grey;
      font-weight: normal;
      padding: 0 3px;
    }

    &--previous,
    &--next {
      display: none;

      @include mq($bp-small) {
        display: inline-block;

        .sidebar & {
          display: none;
        }
      }
    }
  }
}
