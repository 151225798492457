//STANDAARD

.list {
  margin: 0 0 $gutter;

  @include mq($bp-medium) {
    margin-bottom: $gutter * 1.5;
  }

  @include mq($bp-large) {
    margin-bottom: $gutter * 3;
  }

  .band--content & {
    list-style: inherit;
    margin: 0 0 $gutter 20px;
  }

  &--checks {

    .band--content & {
      list-style: none;
      margin-left: 0;
    }

    .list__item {
      margin-bottom: $gutter / 2;
      padding-left: 1.5em;
      position: relative;

      &::before {
        color: $color-primary;
        content: $fa-var-check;
        font-family: 'FontAwesome';
        left: 0;
        margin-right: $gutter / 2;
        position: absolute;
        top: 0;
      }
    }
  }

  &__item {

    .list {
      margin-bottom: 5px;
      margin-left: $gutter;
    }
  }

  // LIJST VOOR ARTIKELEN EN AGENDA

  &--articles,
  &--events {
    border-top: 1px solid $color-grey-lighter;
    line-height: $font-lineheight-alt;
    list-style: none;
    margin: 0 0 $gutter;

    &__item {
      border-bottom: 1px solid $color-grey-lighter;
    }
  }

  &__link {
    display: block;
    padding: 5px 0;
    text-decoration: none;

    &--title {
      color: $color-primary;
      display: block;
      margin-bottom: 5px;
    }

    &:hover .list__link--title {
      color: $color-secondary;
    }

    &--date,
    &--location {
      @include font-size(12);

      color: $color-grey;
      display: block;
    }
  }

  &--custom-icon,
  .band--content &--custom-icon {
    list-style: none;
    margin-left: 0;

    .list__item {

      &::before {
        color: $color-primary;
        content: $fa-var-angle-right;
        font-family: 'FontAwesome';
        margin-right: 10px;
      }
    }
  }

  .fa {
    margin-right: 5px;
  }
}

ol.list--checks {

  li {
    list-style: decimal;
    margin-left: 1.5em !important; /* stylelint-disable-line declaration-no-important */
    padding-left: 10px !important; /* stylelint-disable-line declaration-no-important */

    &::before {
      display: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.band--footer .list__item {

  i {
    display: none !important;  /* stylelint-disable-line declaration-no-important */
  }
}
