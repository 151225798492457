.sidebar__item {
  @include clearfix();
  @include font-size(16);

  background: $color-grey-lighter;
  box-sizing: border-box;
  margin-bottom: $gutter;
  padding: $gutter $gutter 0;
  position: relative;
  width: 100%;

  &--color1 {
    background: $color-primary;
    color: $color-white;

    > *,
    .title {
      color: $color-white;
    }
  }

  &--color2 {
    background: $color-secondary;
    color: $color-white;

    > *,
    .title {
      color: $color-white;
    }
  }

  .call-to-action {
    @if $website == tcm {
      background: $color-primary url('../images/logo-link.png') 20px 20px no-repeat;
      background-size: 28px 30px;
      padding: $gutter $gutter $gutter 60px;
    }

    @else {
      background-color: $color-primary;
      padding: $gutter;
    }

    box-sizing: border-box;
    color: $color-white;
    display: block;
    margin-bottom: 0;
    margin-left: -20px;
    margin-top: -20px;
    text-decoration: none;
    width: calc(100% + 40px);

    @include mq($bp-medium) {
      @if $website == tcm {
        background-size: 56px 61px;
        padding-left: 100px;
      }

      min-height: 60px;
    }

    + .call-to-action {
      margin-top: $gutter;
    }

    .title {
      color: $color-white;
      margin-bottom: 5px;
    }

    > *:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: shade($color-primary, 25);
      color: $color-white;
    }
  }
}
