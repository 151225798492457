.band.band--services {

  .title--h2 {
    @include font-size(28);

    color: $color-black;
    font-weight: normal;
    margin-bottom: $gutter;

    @include mq($bp-small) {
      @include font-size(34);
    }

    @include mq($bp-medium) {
      @include font-size(40);
    }

    @include mq($bp-large) {
      @include font-size(48);
    }
  }

  .title--h3 {
    @include font-size(24);

    color: $color-primary;

    @include mq($bp-medium) {
      @include font-size(26);
    }

    @include mq($bp-large) {
      @include font-size(28);
    }
  }

  .paragraph {
    font-size: 90%;
    max-width: 90%;

    @include mq($bp-medium) {
      font-size: 100%;
    }
  }

  .link {

    &--read-more {
      color: $color-black;

      &:hover {
        color: $color-primary;

        &::after {
          color: $color-primary;
        }
      }

      &::after {
        color: $color-secondary;
      }
    }
  }

  img {
    margin-bottom: $gutter;
    width: 100%;
  }

  .column {
    @include mq($bp-small, max) {

      &:first-child {
        border-bottom: 1px solid $color-grey-lighter;
        margin-bottom: $gutter;
      }
    }
  }
}
