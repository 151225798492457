.band--menu {
  background-color: $color-secondary;
  color: $color-white;
  max-height: 0;
  overflow: hidden;
  position: relative;
  transition: all 300ms ease;

  @include mq($bp-medium, max) {

    .show-menu & {
      max-height: none;

      @if $website == tcm {
        padding-top: 150px;
      }

      @else {
        padding-top: 50px;

        &::before {
          background: $color-primary;
          content: '';
          height: 1px;
          left: 0;
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 50px;
          width: 100%;
          z-index: 2;
        }
      }
    }
  }
}
