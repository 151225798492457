.band--top {
  // display: none;
  height: $topmenu-height;
  left: 0;
  // overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;

  @if $website == tcm {
    background: transparentize($color-black, .75);
  }

  @else {
    background: transparentize($color-white, .25);
  }

  @include mq($bp-medium) {
    display: block;
  }

  .band__content {
    @include clearfix();

    padding: 0;
    text-align: right;
  }
}
