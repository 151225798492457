@if $mobile-menu == true {

  $mobile-menu-width: 320px;
  $mobile-menu-gutter: 20px;
  $mobile-menu-margin: 20px;
  $mobile-menu-bgcolor: $color-primary;
  $mobile-menu-border: 1px solid darken($mobile-menu-bgcolor, 5);
  $mobile-menu-header-bgcolor: darken($mobile-menu-bgcolor, 10);
  $mobile-menu-link-bgcolor: darken($mobile-menu-bgcolor, 10);
  $mobile-menu-text: $color-white;
  $mobile-menu-padding: 10px;
  $mobile-menu-button-width: 30px;

  .mobile {
    background: $mobile-menu-bgcolor;
    bottom: 0;
    left: 0;
    position: fixed;
    top: 0;
    transform: translate(-100%, 0);
    transition: transform .3s cubic-bezier(.05, .45, .35, .95);
    width: $mobile-menu-width;
    z-index: -1;

    @include mq($bp-medium) {
      display: none;
    }

    .show-menu & {
      transform: translate(0, 0);
      z-index: 2;
    }

    &__wrapper {
      height: calc(100% - 50px);
      margin: 50px auto 0;
      overflow: hidden;
      overflow-y: auto;
      position: relative;
      width: $mobile-menu-width - (2 * $mobile-menu-gutter);

      .no-csscalc & {
        height: 100%;
      }

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    .toggle-menu {
      background: $mobile-menu-link-bgcolor;
      position: absolute;
      right: 20px;
      top: 10px;
    }

    &-menu {
      list-style: none;
      transition: all .25s cubic-bezier(.05, .45, .35, .95);
      // breedte voor alle (sub)menu's wordt berekend met jquery

      &.close {
        margin-left: -100%;
      }

      &--main {
        border-top: $mobile-menu-border;
        margin-top: $mobile-menu-margin;
      }

      &--sub {
        left: 100%;
        position: absolute;
        top: 0;

        li:last-child {
          margin-bottom: $mobile-menu-margin;
        }

        &.show {
          height: auto;
          left: 0;

          ul {
            left: 100%;
          }
        }

        &.close {
          left: 0%;

          .show {
            left: 100%;
          }
        }
      }

      &__item {
        clear: left;

        &--header a {
          background: $mobile-menu-header-bgcolor;
          color: $mobile-menu-text;
          margin-top: $mobile-menu-margin;
          padding: $mobile-menu-padding ($mobile-menu-padding + $mobile-menu-button-width);
          text-align: center;

          span {
            bottom: 0;
            left: 0;
            position: absolute;
            text-align: center;
            top: 0;

            i {
              left: 10px;
              margin-top: -8px;
              position: absolute;
              top: 50%;
            }
          }
        }
      }

      &__link {
        @include font-size(14);

        border-bottom: $mobile-menu-border;
        color: $mobile-menu-text;
        display: block;
        font-family: $font-default;
        font-weight: bold;
        line-height: 1.2;
        padding: $mobile-menu-padding;
        position: relative;
        text-decoration: none;

        &--parent {
          padding-right: $mobile-menu-padding + $mobile-menu-button-width;

          .open-sub {
            background: $mobile-menu-link-bgcolor;
            border-radius: 50%;
            height: $mobile-menu-button-width;
            margin-top: -($mobile-menu-button-width / 2);
            position: absolute;
            right: 0;
            text-align: center;
            top: 50%;
            width: $mobile-menu-button-width;

            i {
              color: $mobile-menu-text;
              left: 50%;
              margin-left: -4px;
              margin-top: -7px;
              position: absolute;
              top: 50%;
            }
          }
        }
      }
    }
  }
}
