.calendar,
.band--content .calendar {
  border: 1px solid $color-grey-lighter;
  display: flex;
  font-size: 85%;
  margin-bottom: 40px;

  @include mq($bp-medium) {
    font-size: 100%;
  }

  .weeks__week--2 & {
    background-color: $color-status-error;
  }

  .weeks__week--3 & {
    background-color: $color-status-info;
  }

  &__day {
    border-left: 1px solid $color-grey-lighter;
    box-sizing: border-box;
    flex: 0 0 20%;
    max-width: 20%;

    &:nth-child(1) {
      border-left: 0;
    }

    &--disabled {

      .calendar__time,
      .calendar__time:hover {
        background-color: $color-grey-lighter;
        color: $color-grey-light;
      }
    }
  }

  &__time {
    border-top: 1px solid $color-grey-lighter;
    cursor: pointer;
    min-height: 60px;
    padding: 5px;
    transition: all 200ms;

    @include mq($bp-medium) {
      padding: 30px 10px;
    }

    &:nth-child(1) {
      border-top: 0;
    }

    &:hover {
      background-color: $color-primary;
    }

    &--selected {
      background-color: $color-secondary !important; /* stylelint-disable-line declaration-no-important */
      color: $color-white !important; /* stylelint-disable-line declaration-no-important */
      font-weight: bold;
    }
  }

  &__content {

    &--small {
      display: block;

      @include mq($bp-medium) {
        display: none;
      }
    }

    &--large {
      display: none;

      @include mq($bp-medium) {
        display: block;
      }
    }
  }
}

.message,
.band--content .message {
  border: 1px solid $color-status-info;
  margin-bottom: $gutter;
  padding: 5px 10px;
  text-align: center;

  &.error {
    background-color: lighten($color-status-error, 50);
    border-color: $color-status-error;
  }

  &.ok {
    border-color: $color-status-ok;
  }
}

.appointment,
.band--content .appointment {
  text-align: center;

  &__location {
    margin-bottom: 20px;
    text-align: left;

    .list {
      list-style: none;
      margin-left: 0;

      label {
        align-items: baseline;
        display: flex;
      }

      input {
        margin-right: 10px;
        position: relative;
        top: 2px;
      }
    }
  }

  &__dropdown {
    min-width: 200px;

    .error {
      border: 1px solid $color-status-error;
    }
  }

  &__fields {
    margin: 0 auto;
    max-width: 400px;
    text-align: left;

    .paragraph {
      font-size: 90%;
      font-style: italic;
    }
  }

  &__field {
    border: 1px solid $color-grey-light;
    box-sizing: border-box;
    font-family: $font-default;
    font-size: inherit;
    margin-bottom: 10px;
    padding: 5px 10px;
    width: 100%;

    &::placeholder {
      color: $color-grey-light;
      font-style: italic;
    }

    &[name='appointment__date'] {
      border-color: $color-grey-lighter;
      color: $color-grey-light;
    }

    &.error {
      border-color: $color-status-error;
      color: $color-status-error;
    }
  }

  .button {
    width: 100%;
  }
}

.weeks,
.band--content .weeks {

  &__navigation {
    color: $color-secondary;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__previous,
  &__number,
  &__next {
    display: inline-block;
    margin: 0 5px;

    &--disabled {
      color: $color-grey-light !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &__previous,
  &__next {
    cursor: pointer;

    &:hover {
      color: $color-primary;
    }
  }
}
