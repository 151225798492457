.band--logo {
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: $topmenu-height;
  z-index: 3;

  .body--home & {

    .band__content {
      max-width: none;
    }

    .logo {
      position: relative;

      @include mq($bp-small) {
        left: $gutter;
      }
    }
  }
}

@if $website == tcm {

  .logo {
    display: block;
    max-width: $logo-max-width;
    width: 180px;

    @include mq($bp-small) {
      width: 30vw;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}

@if $website == intereffekt {

  .logo {
    display: block;
    height: 50px;
    left: 0;
    max-width: 400px;
    padding: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 153px);
    z-index: 6;

    &::before,
    &::after {
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    &::after {
      background: url('../images/logo-intereffekt-normal.png') 50% no-repeat;
      background-size: 90% auto;
      z-index: 1;

      @include mq($bp-medium) {
        background-position: 40px 50%;
        background-size: 70% auto;
      }
    }

    &::before {
      background: linear-gradient(to right, transparentize($color-white, .1) 70%, transparentize($color-white, 1));
      z-index: 0;
    }

    @include mq($bp-medium) {
      height: 60px;
      max-width: 540px;
      top: 100px;
    }

    @include mq($bp-large) {
      top: 115px;
    }

    @include mq(1400) {
      height: 76px;
      max-width: 680px;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}

.toggle {
  color: $color-white;
  display: flex;
  position: absolute;
  right: $gutter;
  text-decoration: none;
  top: $gutter;

  @include mq($bp-medium) {
    top: 50%;
    transform: translateY(-50%);
  }

  span {
    display: none;

    @include mq($bp-medium) {
      @include font-size(16);

      display: inline-block;
      line-height: $toggle-button-height + px;
      margin-right: $gutter / 2;
    }
  }

  i {
    @include font-size($toggle-button-height);
  }

  &:hover {
    opacity: .8;
  }
}
