$menu-space: $gutter / 1.5;
$menu-border-width: 1px;
$menu-border-color: transparentize($color-white, .7);
$menu-bghover-color: transparentize($color-primary, .8);
$menu-textcolor: $color-white;

@if $website == intereffekt {
  $menu-border-color: transparentize($color-black, .7);
  $menu-bghover-color: transparentize($color-primary, .8);
  $menu-textcolor: $color-black;
}

$menu-border: $menu-border-width solid $menu-border-color;

.topmenu {
  @include clearfix();

  display: inline;
  float: right;

  @include mq(1490) {
    border-right: $menu-border;
  }

  &__item {
    display: none;
    float: left;
    position: relative;

    @include mq($bp-medium) {
      display: inline;
    }

    &.hover,
    &:hover {
      visibility: visible;

      > ul {
        visibility: visible;
      }
    }

    &--search,
    &--lang,
    &--toggle {
      border-left: $menu-border;
      display: inline;

      a {

        &::after {
          display: none;
        }

        &:hover {
          background-color: $menu-bghover-color;
        }
      }
    }

    &--lang {

      .fa {
        padding-left: .3em;
      }
    }

    &--toggle {
      @include mq($bp-medium) {
        display: none;
      }

      .fa-close {
        display: none;

        .show-menu & {
          display: inline-block;
        }
      }

      .show-menu & .fa-bars {
        display: none;
      }
    }

    ul {
      left: 0;
      position: absolute;
      right: 0;
      top: 100%;
      visibility: hidden;

      li {
        display: block;
        float: left;
        width: 100%;

        a {
          background: $color-white;
          color: $color-secondary;

          &:hover {
            background: $color-white;
            color: $color-primary;
          }
        }
      }
    }
  }

  a {
    @include font-size(16);

    color: $menu-textcolor;
    display: block;
    height: $topmenu-height;
    line-height: $topmenu-height;
    min-width: 30px;
    overflow: hidden;
    padding: 0 $menu-space;
    position: relative;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;

    @include mq($bp-small, max) {
      padding: 0 $gutter / 2;
      width: 30px;
    }

    @include mq($bp-large) {
      @include font-size(18);
    }

    &::after {
      background: $color-primary;
      bottom: 0;
      content: '';
      height: $topmenu-bar-height;
      left: $menu-space;
      position: absolute;
      right: $menu-space;
      transform: translateY($topmenu-bar-height);
      transition: transform 200ms ease;
    }

    &:hover {

      &::after {
        transform: translateY(-7px);
      }
    }
  }

  &__item--selected a {
    color: $color-primary;
  }

  @include mq($bp-medium, max) {

    &__item--search a,
    &__item--toggle a {
      @include font-size(24);
    }
  }
}
