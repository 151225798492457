//  TITLES

.title {
  @include title-font();

  color: $color-text;
  font-weight: 300;
  margin-bottom: .2em;

  .link {
    color: $color-text;
    text-decoration: none;
  }

  &--h1 {
    @include font-size(20);

    font-weight: 700;
    margin-bottom: $gutter;

    @include mq($bp-small) {
      @include font-size(32);
    }

    @include mq($bp-medium) {
      @include font-size(36);

      margin-bottom: $gutter * 2;
    }

    @include mq($bp-large) {
      @include font-size(40);
    }
  }

  &--header {
    @include text-overflow();

    color: $color-white;
    font-size: 200% !important; /* stylelint-disable-line declaration-no-important */
    font-weight: 700 !important; /* stylelint-disable-line declaration-no-important */
    left: -20px;
    max-width: 1680px !important; /* stylelint-disable-line declaration-no-important */
    position: relative;
    text-align: center;
    width: 100vw !important; /* stylelint-disable-line declaration-no-important */

    @include mq($bp-small) {
      font-size: 400% !important; /* stylelint-disable-line declaration-no-important */
      left: -40px;
    }

    @include mq($bp-medium) {
      font-size: 600% !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &--h2 {
    @include font-size(24);

    .band__content & {
      @include font-size(18);

      @include mq($bp-small) {
        @include font-size(24);
      }
    }
  }

  &--h3 {
    @include font-size(20);

    .sidebar & {
      font-weight: 700;
      margin-bottom: $gutter;
    }

    .band--footer & {
      font-weight: 500;
    }
  }

  &--h4,
  &--h5,
  &--h6 {
    @include font-size(18);
  }

  &--centered {
    text-align: center;
  }

  &--band {
    @include font-size(18);

    font-weight: 300;
    margin-bottom: $gutter;

    @include mq($bp-small) {
      @include font-size(28);
    }

    @include mq($bp-medium) {
      @include font-size(36);

      margin-bottom: $gutter * 2;
    }

    @include mq($bp-large) {
      @include font-size(40);
    }
  }
}

.search-result__subtitle {
  font-weight: 700 !important; /* stylelint-disable-line declaration-no-important */
  margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */
}
