.funds-buttons {
  @include mq($bp-medium) {
    display: flex;
  }

  &__button {
    background-color: $color-primary;
    border: 1px solid $color-primary;
    color: $color-white;
    display: block;
    line-height: 1.2;
    margin-bottom: $gutter;
    margin-right: $gutter;
    min-width: 120px;
    padding: $gutter / 2 $gutter;
    text-decoration: none;
    transition: all 200ms ease;

    &:hover {
      background-color: $color-white;
      color: $color-primary;
    }

    &--active {
      background-color: $color-white;
      color: $color-primary;
    }
  }

  &__title {
    font-family: $font-title;
    font-weight: bold;
  }

  &__subtitle {
    font-family: $font-title;
    font-weight: 300;
  }
}
