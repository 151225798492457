.band.band--about {
  @include font-size(16);

  background-color: rgba($color-grey-lighter, .5);
  padding-bottom: $gutter;
  padding-top: $gutter;

  h3 {
    color: $color-black;
    margin-bottom: $gutter;
    text-transform: uppercase;
  }
}
