.certificates {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: -$gutter;

  &__certificate {
    flex: 0 0 auto;
    margin: 0 5% $gutter;
    max-width: 20%;

    @include mq($bp-medium) {
      flex: 0 0 auto;
      margin: 0 2.5% $gutter;
      max-width: 15%;
    }

    img {
      max-height: 90px;
      max-width: 100%;
    }
  }
}
