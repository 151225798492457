.band--newsletter {
  @if $website == tcm {
    background: $color-secondary;
    background-attachment: fixed;
    background-image: url('../images/pattern.jpg');
    background-position: 50%;
    background-size: 145px 174px;
  }

  @else {
    background: $color-grey;
  }

  padding-top: $gutter;

  .band__content {
    color: $color-white;
    text-align: center;

    .title {
      color: $color-white;
    }
  }
}

// .newsletter {
//   margin-bottom: $gutter;

//   @include mq($bp-small) {
//     @include mq($bp-large, max) {
//       flex: 1 1 60%;
//       margin-right: 6%;
//       max-width: 60%;
//     }
//   }

//   @include mq($bp-large) {
//     margin-bottom: $gutter * 2;
//   }
// }

.newsletter__form {
  border-radius: 5px;
  height: $footer-item-size + px;
  margin: 0 auto ($gutter * 1.5);
  max-width: 600px;
  overflow: hidden;
  position: relative;
  width: 80%;

  input {
    @include font-size(14);
    @include base-font();

    border: 0;
    box-sizing: border-box;
    height: $footer-item-size + px;
    line-height: $footer-item-size + px;
    padding: 0 ($footer-item-size - 10px) 0 5px;
    width: 100%;

    @include mq($bp-small) {
      @include font-size(16);
    }

    &:focus {
      outline: none;
    }

    &.error + button {
      background: $color-status-error;
      color: $color-white;
    }
  }

  button {
    @include font-size(21);

    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
    background: $color-primary;
    border: 0;
    color: $color-white;
    cursor: pointer;
    height: $footer-item-size + px;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: $footer-item-size + px;

    &:hover {
      background-color: $color-hover;
      color: $color-white;
    }

    &:focus {
      outline: none;
    }
  }
}
