.menu--main {
  color: $color-white;
  display: flex;
  line-height: 1.2;

  @include mq($bp-small) {
    padding: $gutter;
  }

  .menu {

    &__list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      @include mq($bp-large) {
        flex-wrap: nowrap;
      }

      > .menu__item {
        flex: 1 1 100%;
      }

      .menu__list {
        display: block;
        margin-bottom: $gutter;
        margin-top: $gutter / 2;

        @include mq($bp-large) {
          margin-bottom: 0;
        }

        .menu__list {
          list-style: disc;
          margin-bottom: 5px;
          margin-left: $gutter;
        }
      }
    }

    &__item {
      margin-bottom: $gutter / 2;
    }

    &__link {
      color: $color-white;
      text-decoration: none;
      transition: 200ms all ease;

      &:hover {
        color: rgba($color-white, .8);
      }
    }
  }

  .title {
    @include font-size(25);

    color: $color-white;
  }
}
