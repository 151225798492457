.band.band--blog {
  background-color: rgba($color-grey-lighter, .5);
  padding-bottom: $gutter * 1.5;
  padding-top: $gutter * 1.5;
  text-align: center;

  .band__content {

    > .title {
      margin-bottom: $gutter;
    }

    > .paragraph {
      margin-bottom: $gutter * 2;
    }
  }
}
