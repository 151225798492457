.band {
  min-width: 300px;
  position: relative;

  &__content {
    margin: 0 auto;
    max-width: 1490px;
    padding: $gutter;
    position: relative;
    z-index: 2;
  }

  &__background {
    background-position: center;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
}

@import 'footer/',
  'about',
  'background',
  'blog',
  'certificates',
  'columns',
  'content',
  'header',
  'home',
  'logo',
  'maps',
  'menu',
  'newsletter',
  'rating',
  'read-more',
  'services',
  'team-background',
  'team-members',
  'top',
  'logos';
