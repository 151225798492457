.slides {
  overflow: hidden;
  position: relative;

  .slide {
    overflow: hidden;
    position: relative;

    @include mq($bp-medium) {
      max-height: 90vh;
    }

    &__background {
      background-position: center;
      background-size: cover;
      box-sizing: border-box;
      min-height: 490px;
      position: relative;
      z-index: 0;

      @include mq($bp-small) {
        box-sizing: content-box;
        min-height: 200px;
        padding-bottom: 55%;
      }

      @include mq($bp-medium) {
        padding-bottom: 50%;
      }

      @include mq($bp-large) {
        min-height: 100px;
      }

      @include mq(1400) {
        padding-bottom: 700px;
      }

      @if $website == tcm {

        &::before {
          background-color: transparentize($color-black, .7);
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
        }
      }
    }

    &__text {
      @include font-size(16);

      bottom: 0;
      color: $color-white;
      left: $gutter;
      max-width: 100%;
      position: absolute;
      right: 0;
      text-align: center;
      top: 160px;

      @if $website == tcm {
        top: 160px;
      }

      @else {
        // text-shadow: 0 0 10px transparentize($color-black, .5);
        top: 80px;
      }

      @include mq($bp-small) {
        left: $gutter * 2;
        right: auto;
        text-align: left;

        @if $website == tcm {
          top: 180px;
        }
      }

      @include mq($bp-medium) {
        // max-width: 650px;
        @if $website == tcm {
          top: 190px;
        }

        @else {
          top: 210px;
        }
      }

      @include mq($bp-large) {
        @include font-size(18);

        // @if $website == tcm {
        top: 250px;
        // }

        // @else {
        //   top: 220px;
        // }
      }
    }

    p {
      @include font-size(20);

      display: block;
      font-weight: 300;
      line-height: $font-lineheight-alt;
      margin-bottom: $gutter / 2;

      @include mq($bp-small) {
        @include font-size(32);

        margin-bottom: $gutter;
        max-width: 80vw;
      }

      @include mq(1280) {
        @include font-size(42);

        // max-width: 650px;
      }

      a {
        color: $color-white;
        text-decoration: none;
      }
    }

    .link {

      &--read-more {
        color: $color-primary;

        &:hover {
          color: $color-white;
        }
      }
    }
  }

  .owl-dots {
    bottom: 0;
    display: none;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;

    @include mq($bp-small) {
      bottom: $gutter * 1.5;
      display: block;
    }

    .owl-dot {
      background-color: $color-grey-light;
      border-radius: 50%;
      display: inline-block;
      height: 15px;
      margin: 0 5px;
      width: 15px;

      &.active {
        background-color: $color-primary;
      }
    }
  }

  .owl-nav {
    bottom: $gutter;
    display: flex;
    justify-content: center;
    left: $gutter * 2;
    position: absolute;
    right: $gutter * 2;
    z-index: 3;

    @include mq($bp-small) {
      bottom: 0;
      right: auto;
    }

    .owl-prev,
    .owl-next {
      @include font-size(28);

      align-items: center;
      background-color: $color-white;
      color: $color-secondary;
      display: flex;
      flex: 0 0 40px;
      height: 40px;
      justify-content: center;
      transition: all 200ms ease;
      width: 40px;

      @include mq($bp-small) {
        @include font-size(40);

        flex: 0 0 60px;
        height: 60px;
        width: 60px;
      }

      @include mq($bp-large) {
        @include font-size(40);

        flex: 0 0 80px;
        height: 80px;
        width: 80px;
      }

      &:hover {
        background-color: $color-secondary;
        color: $color-white;
      }
    }

    .owl-prev::after {
      content: $fa-var-angle-left;
      font-family: 'FontAwesome';
    }

    .owl-next::after {
      content: $fa-var-angle-right;
      font-family: 'FontAwesome';
    }
  }
}
