.band--maps {
  background-color: $color-grey-lighter;

  .google-maps {
    min-height: 260px;

    @include mq($bp-medium) {
      min-height: 400px;
    }

    @include mq($bp-large) {
      min-height: 500px;
    }

    @include mq($bp-xlarge) {
      min-height: 580px;
    }

    &__window {
      background-color: rgba($color-primary, .8);
      color: $color-black;
      display: none;
      padding: $gutter;

      @include mq($bp-large) {
        left: $gutter;
        min-width: 260px;
        position: absolute;
        top: $gutter;
      }

      @include mq($bp-xlarge) {
        left: auto;
        min-width: 350px;
        padding: $gutter * 2;
        position: absolute;
        right: $gutter * 2;
        top: $gutter * 2;
      }

      &--active {
        display: block;
      }

      .title {
        color: $color-black;

        &--h2 {
          @include font-size(40);

          border-bottom: 1px solid $color-black;
          margin-bottom: $gutter;
          padding-bottom: $gutter / 2;
          text-transform: uppercase;
        }
      }
    }
  }

  p {
    margin-bottom: 0;
  }
}
